<template>
  <div id="app" class="app">
    <div v-if="$route.meta.template === templates.EB360">
      <AppEb2Header v-if="$route.meta.isEb360HeaderRequired" />
      <div>
        <AppEb2Sidebar v-if="$route.meta.isEB360SideBarRequired" />
        <div
          :class="[
            'app__router-view',
            !isSidebarOpened && 'app__router-view--menu-closed',
            !$route.meta.isEB360SideBarRequired &&
              'app__router-view--no-sidebar'
          ]"
        >
          <Breadcrumbs />
          <router-view />
        </div>
      </div>
    </div>
    <div v-else>
      <AppEb2HeaderEmails />
      <router-view />
    </div>
    <portal-target name="tooltip" />
    <b-toast
      :visible="showVersionNotification"
      variant="info"
      title="The EB360 application has undergone an upgrade."
      :auto-hide-delay="timers.VERY_SLOW"
    >
      To complete this, you will be logged out automatically. You will need to
      log in again to finalize the upgrade process.<br /><br />
      If you logged in by SSO please ensure you have logged out from your SSO
      provider.
    </b-toast>
    <portal-target name="dropdown" />
  </div>
</template>

<script>
import AppEb2Header from "./components/header/eb2Header";
import AppEb2HeaderEmails from "./components/header/eb2HeaderEmails";
import AppEb2Sidebar from "./components/sidebar/eb2Sidebar";
import Breadcrumbs from "@/molecules/Breadcrumbs/Breadcrumbs";
import {
  actionName,
  getterName,
  localStorageItem,
  mutationName,
  templates,
  timers
} from "@/constants";
import { config } from "@/configs";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { makePermissionsDTO, makeRolesDTO } from "@/services/user/dto/user.dto";
import { isJsonString, getCurrentVersion } from "@/utils";

export default {
  name: "app",
  data() {
    return {
      isAppLoading: true,
      config,
      templates,
      currentVersion: getCurrentVersion(),
      timers,
      showVersionNotification: false
    };
  },
  components: {
    AppEb2Header,
    AppEb2Sidebar,
    AppEb2HeaderEmails,
    Breadcrumbs
  },
  computed: {
    ...mapGetters({
      hasNoUserData: getterName.USER.HAS_NO_USER_DATA,
      isUserAuthenticated: getterName.AUTH.IS_USER_AUTHENTICATED,
      hasPublicPlatformSettings:
        getterName.PLATFORM_SETTINGS.HAS_PUBLIC_PLATFORM_SETTINGS,
      isUserThirdParty: getterName.USER.IS_USER_THIRD_PARTY
    }),
    ...mapState({
      isSidebarOpened: (state) => state.isSidebarOpened,
      lastRecordedUIVersion: (state) => state.version.lastRecordedUIVersion
    })
  },
  async created() {
    this.compareBuildVersions();
    await this.setVuexStoreAfterSSOLogin();

    if (this.isUserAuthenticated) {
      await this.fetchEB360AppData();
    }
    if (!this.hasPublicPlatformSettings) {
      await this.fetchPublicPlatformSettings();
    }
    const lang = localStorage.getItem(localStorageItem.FORM_TRANSLATIONS);
    if (lang && this.isUserThirdParty) {
      this.$store.state.form1.formTranslations = lang.split(",");
    }
    this.setAlign();
  },
  methods: {
    ...mapMutations({
      setRegistrationStatus: mutationName.USER.SET_REGISTRATION_STATUS,
      setMemberGroupId: mutationName.USER.SET_MEMBER_GROUP_ID,
      setLandingPage: mutationName.USER.SET_LANDING_PAGE,
      setPasswordCreated: mutationName.USER.SET_PASSWORD_CREATED,
      setCompanyId: mutationName.COMPANY.SET_COMPANY_ID,
      setRoles: mutationName.USER.SET_ROLES,
      setPermissions: mutationName.USER.SET_PERMISSIONS,
      setUserId: mutationName.USER.SET_USER_ID,
      setCompanyName: mutationName.COMPANY.SET_COMPANY_NAME,
      setEmail: mutationName.USER.SET_EMAIL,
      setFormDefaultLanguage: mutationName.USER.SET_FORM_DEFAULT_LANGUAGE,
      setUserFirstName: mutationName.USER.SET_USER_FIRST_NAME,
      setUserLastName: mutationName.USER.SET_USER_LAST_NAME,
      setUserFullName: mutationName.USER.SET_USER_FULL_NAME,
      setLoginType: mutationName.USER.SET_LOGIN_TYPE,
      setUserTourStatus: mutationName.USER.SET_USER_TOUR_STATUS,
      setPlatformVersion: mutationName.USER.SET_PLATFORM_VERSION,
      setLastRecordedUIVersion:
        mutationName.VERSION.SET_LAST_RECORDED_UI_VERSION
    }),
    ...mapActions({
      fetchPublicPlatformSettings:
        actionName.PLATFORM_SETTINGS.FETCH_PUBLIC_PLATFORM_SETTINGS,
      fetchEB360AppData: actionName.AUTH.FETCH_EB360_APP_DATA,
      logOut: actionName.AUTH.LOG_OUT
    }),
    compareBuildVersions() {
      if (this.lastRecordedUIVersion !== this.currentVersion) {
        this.handleVersionChangeEvent();
      }
    },
    async handleVersionChangeEvent() {
      const delay = this.isUserAuthenticated ? timers.VERY_SLOW : timers.NONE;

      if (this.isUserAuthenticated) {
        this.showVersionNotification = true;
      }

      setTimeout(async () => {
        await this.logOut();
        this.setLastRecordedUIVersion(this.currentVersion);
        window.location.reload(true);
      }, delay);
    },
    setAlign() {
      const selectedLang = localStorage.getItem(
        localStorageItem.FORM_DEFAULT_LANGUAGE
      );
      this.$store.state.rightAlign =
        selectedLang === "arabic" || selectedLang === "hebrew";
    },
    async setVuexStoreAfterSSOLogin() {
      if (this.hasNoUserData) {
        const rolesJsonString = localStorage.getItem(localStorageItem.ROLES);
        const roles = isJsonString(rolesJsonString)
          ? JSON.parse(rolesJsonString)
          : [];
        const permissionsJsonString = localStorage.getItem(
          localStorageItem.PERMISSIONS
        );
        const permissions = isJsonString(permissionsJsonString)
          ? JSON.parse(localStorage.getItem(localStorageItem.PERMISSIONS))
          : [];

        this.setRegistrationStatus(
          localStorage.getItem(localStorageItem.REGISTRATION_STATUS)
        );
        this.setMemberGroupId(
          localStorage.getItem(localStorageItem.USER_GROUP_ID)
        );
        this.setLandingPage(
          localStorage.getItem(localStorageItem.LANDING_PAGE)
        );
        this.setPasswordCreated(
          localStorage.getItem(localStorageItem.PASSWORD_CREATED)
        );
        this.setCompanyId(localStorage.getItem(localStorageItem.COMPANY_ID));
        this.setPermissions(permissions?.map(makePermissionsDTO));
        this.setRoles(makeRolesDTO(roles));
        this.setUserId(localStorage.getItem(localStorageItem.USER_ID));
        this.setCompanyName(
          localStorage.getItem(localStorageItem.COMPANY_NAME)
        );
        this.setEmail(localStorage.getItem(localStorageItem.USER_EMAIL));
        this.setFormDefaultLanguage(
          localStorage.getItem(localStorageItem.FORM_DEFAULT_LANGUAGE)
        );
        this.setUserFirstName(
          localStorage.getItem(localStorageItem.USER_FIRST_NAME)
        );
        this.setUserLastName(
          localStorage.getItem(localStorageItem.USER_LAST_NAME)
        );
        this.setUserFullName(
          localStorage.getItem(localStorageItem.USER_FULL_NAME)
        );
        this.setLoginType(localStorage.getItem(localStorageItem.LOGIN_TYPE));
        this.setUserTourStatus(
          localStorage.getItem(localStorageItem.USER_TOUR_STATUS)
        );
        this.setPlatformVersion(
          localStorage.getItem(localStorageItem.PLATFORM_VERSION)
        );
      }
    }
  }
};
</script>

<style lang="scss">
body {
  overflow: hidden;
  height: 100vh;
}
#app {
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $black;
  position: relative;
}
.app {
  &__router-view {
    padding: 10px 16px 10px 240px;
    transition: $transition-duration;

    &--menu-closed {
      padding-left: 40px;
    }

    &--no-sidebar {
      padding-left: 10px;
    }
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: $grey;
  border-radius: $border-radius;
}

::-webkit-scrollbar-thumb {
  background-color: rgba($davys-grey, 0.75);
  border-radius: $border-radius;

  &:hover {
    background-color: $davys-grey;
  }
}

.click-cursor:hover {
  cursor: pointer;
}

.card-wrapper {
  box-shadow: 0 0 0 rgba($black, 0.15);
  transition: box-shadow $transition-duration ease-in-out;
}

.card-wrapper:hover {
  box-shadow: 2px 5px 10px 0px $spanish-gray;
  transition: box-shadow $transition-duration ease-in-out;
}

.form-control-small .vs__dropdown-toggle {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
</style>
