<template>
  <b-container fluid>
    <b-spinner
      v-if="isLoading"
      type="grow"
      class="text-center"
      style="margin-top: 10%; position: absolute"
      variant="primary"
    ></b-spinner>
    <b-navbar toggleable="lg" type="dark" variant="light">
      <b-navbar-brand href="#" class="text-dark">
        <strong>Forms</strong>
      </b-navbar-brand>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-form>
            <b-button
              class="my-2 my-sm-0 mr-2"
              variant="outline-primary"
              size="sm"
              @click="showNewCreateFormModal = !showNewCreateFormModal"
            >
              <font-awesome-icon icon="plus" />
            </b-button>
            <b-button
              variant="outline-info"
              size="sm"
              style="margin-right: 30px"
              @click="reloadTable"
            >
              <font-awesome-icon icon="sync" />
            </b-button>

            <b-form-group
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
            >
              <b-input-group size="sm">
                <b-form-input
                  v-model="filter"
                  type="search"
                  id="filterInput"
                  placeholder="Type to Search"
                ></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''"
                    >Clear</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-nav-form>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <b-table
      class="mx-1"
      sticky-header="calc(100vh - 200px)"
      ref="table"
      id="my-table"
      :busy.sync="isLoading"
      :items="myProvider"
      :fields="fields"
      :filter="filter"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      responsive="sm"
    >
      <template v-slot:cell(type)="data">
        <p>{{ formTypes[data.item.type - 1] }}</p>
      </template>

      <template v-slot:cell(created_updated)="data">
        <p>{{ age(data.item.created_at) }}</p>
      </template>

      <template v-slot:cell(title)="data">
        <a href="#" @click="getForm(data.item.id, '/form/configuration')">{{
          data.item.title
        }}</a>
      </template>

      <template v-slot:cell(is_active)="data">
        <b-badge :variant="data.item.is_active == 1 ? 'success' : 'warning '">{{
          data.item.is_active == 1 ? "Published" : "Draft "
        }}</b-badge>
      </template>

      <template v-slot:cell(actions)="data">
        <font-awesome-icon
          v-b-tooltip.hover
          title="Update Form"
          class="mx-1 text-primary pointer"
          icon="cogs"
          @click="onShowUpdateModal(data.item)"
        />
        <font-awesome-icon
          v-b-tooltip.hover
          title="Move Form"
          class="mx-1 pointer"
          icon="forward"
          @click="onShowMoveModal(data.item)"
        />
        <font-awesome-icon
          v-b-tooltip.hover
          title="Preview Form"
          class="text-success pointer"
          icon="eye"
          @click="viewUserForm(data.item.id, '/user-form')"
          to="/user-form"
        />
        <font-awesome-icon
          v-b-tooltip.hover
          title="Duplicate Form"
          class="mx-1 text-warning pointer"
          icon="copy"
          @click="duplicateFormModal(data.item)"
        />
        <font-awesome-icon
          v-b-tooltip.hover
          title="Unpublish form"
          v-if="data.item.is_active == 1"
          class="mx-1 pointer"
          icon="lock-open"
          @click="onShowUnpublishFormModal(data.item.id)"
        />
        <font-awesome-icon
          :disabled="data.is_active == 0"
          v-b-tooltip.hover
          title="Delete Form"
          :class="
            data.item.is_active == 0
              ? 'mx-1 text-danger pointer'
              : 'mx-1 text-light pointer'
          "
          icon="trash"
        />
      </template>
    </b-table>

    <b-row>
      <b-col cols="1">
        <b-form-select
          v-model="perPage"
          id="perPageSelect"
          size="sm"
          :options="pageOptions"
        ></b-form-select>
      </b-col>
      <b-col>
        <b-pagination
          class="float-right"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
          aria-controls="my-table"
        ></b-pagination>
      </b-col>
    </b-row>

    <b-modal
      id="bv-modal-newform"
      size="lg"
      v-model="showNewCreateFormModal"
      scrollable
      no-close-on-backdrop
      hide-footer
      :hide-header="isGlobalLoader"
    >
      <template v-slot:modal-header>
        <!-- Emulate built in modal header close button action -->

        <h5>Create New Form</h5>
      </template>
      <AppLoader v-if="isGlobalLoader" small />
      <b-form v-if="!submiting && !responseSuccess">
        <b-form-group label="Client">
          <v-select
            :searchable="true"
            :clearable="true"
            label="text"
            code="value"
            v-model="$v.newForm.clientId.$model"
            :state="
              $v.newForm.clientId.$dirty ? !$v.newForm.clientId.$error : null
            "
            aria-describedby
            :options="clients"
          ></v-select>
          <b-form-invalid-feedback id="input-2-live-feedback"
            >This is a required field and must be select One
            option</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group label="Form Title">
          <b-form-input
            placeholder="Enter form title"
            v-model="$v.newForm.formTitle.$model"
            :state="
              $v.newForm.formTitle.$dirty ? !$v.newForm.formTitle.$error : null
            "
            aria-describedby
          ></b-form-input>
          <b-form-invalid-feedback
            >This is a required field and must be at least 5
            characters.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group label="Form Description">
          <b-form-textarea
            placeholder="Enter the form description"
            rows="3"
            v-model="$v.newForm.formDescription.$model"
            :state="
              $v.newForm.formDescription.$dirty
                ? !$v.newForm.formDescription.$error
                : null
            "
            aria-describedby
          ></b-form-textarea>
          <b-form-invalid-feedback
            >This is a required field and must be at least 10
            characters.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group label="Form Type">
          <b-form-radio-group
            id="radio-group-1"
            v-model="$v.newForm.selectFormType.$model"
            :state="
              $v.newForm.selectFormType.$dirty
                ? !$v.newForm.selectFormType.$error
                : null
            "
            :options="optionsFormType"
            name="radio-options"
          ></b-form-radio-group>
          <b-form-invalid-feedback
            >This is a required field and must be select One
            option</b-form-invalid-feedback
          >
        </b-form-group>
      </b-form>

      <div v-else-if="submiting && !responseSuccess">
        <p>Your Form is being submited......</p>
        <b-spinner type="grow" label="Loading..."></b-spinner>
      </div>

      <div v-else>
        <p class="my-2 text-center">
          Your form has been created successfully. Would you like to create
          another form?
          <br />
        </p>
        <p class="text-center mt-3">
          <b-button
            @click="responseSuccess = !responseSuccess"
            variant="primary"
            size="lg"
            >Create a new form</b-button
          >
        </p>
        <p class="my-2 text-center">Would you like to edit that form.</p>
        <p class="text-center mb-5 mt-3">
          <b-button
            title="Edit Form"
            class="mr-2 text-primary"
            @click="getForm(newFormId, '/form/configuration')"
          >
            <font-awesome-icon icon="edit" />
          </b-button>
        </p>
      </div>

      <div class="float-right">
        <b-button
          class="mr-3"
          id="close-btn"
          variant="secondary"
          v-if="!submiting"
          @click="resetForm"
          >Close</b-button
        >
        <b-button
          v-if="!responseSuccess"
          variant="outline-success"
          :disabled="$v.newForm.$invalid"
          @click="createForm"
        >
          <b-spinner v-if="submiting" type="grow" label="Loading..."></b-spinner
          >Create
        </b-button>
      </div>

      <div class="clearfix"></div>
    </b-modal>

    <b-modal
      id="bv-modal-duplicateform"
      size="lg"
      no-close-on-backdrop
      v-model="showDuplicateFormModal"
      scrollable
      hide-footer
    >
      <template v-slot:modal-header>
        <h5>Duplicate Form</h5>
      </template>
      <div>
        <b-form v-if="!submiting && !responseSuccess">
          <b-form-group label="Client">
            <v-select
              :searchable="true"
              :clearable="true"
              label="text"
              code="value"
              value="value"
              v-model="$v.duplicateForm.duplicateClientId.$model"
              :state="
                $v.duplicateForm.duplicateClientId.$dirty
                  ? !$v.duplicateForm.duplicateClientId.$error
                  : null
              "
              aria-describedby
              :options="clients"
            ></v-select>
            {{ duplicateForm.duplicateClientId }}
            <b-form-invalid-feedback
              >This is a required field and must be select One
              option</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group label="Form Title">
            <b-form-input
              placeholder="Enter form title"
              v-model="$v.duplicateForm.duplicateTitle.$model"
              :state="
                $v.duplicateForm.duplicateTitle.$dirty
                  ? !$v.duplicateForm.duplicateTitle.$error
                  : null
              "
            ></b-form-input>
            <b-form-invalid-feedback
              >This is a required field and must be at least 5
              characters.</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group label="Form Description">
            <b-form-textarea
              placeholder="Enter the form description"
              rows="3"
              v-model="$v.duplicateForm.duplicateDescription.$model"
              :state="
                $v.duplicateForm.duplicateDescription.$dirty
                  ? !$v.duplicateForm.duplicateDescription.$error
                  : null
              "
            ></b-form-textarea>
            <b-form-invalid-feedback
              >This is a required field and must be at least 10
              characters.</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group label="Form Type">
            <b-form-radio-group
              id="radio-group-1"
              v-model="$v.duplicateForm.duplicateselectFormType.$model"
              :state="
                $v.duplicateForm.duplicateselectFormType.$dirty
                  ? !$v.duplicateForm.duplicateselectFormType.$error
                  : null
              "
              :options="optionsFormType"
              name="radio-options"
            ></b-form-radio-group>
            <b-form-invalid-feedback
              >This is a required field and must be select One
              option</b-form-invalid-feedback
            >
          </b-form-group>
        </b-form>
        <div v-else-if="submiting && !responseSuccess">
          <p>Your Form is being submited......</p>
          <b-spinner type="grow" label="Loading..."></b-spinner>
        </div>

        <div v-else>
          <p class="my-2 text-center">
            Your form has been created successfully.
            <br />
          </p>
        </div>
        <div class="float-right">
          <b-button
            class="mr-3"
            id="close-btn"
            variant="danger"
            v-if="!submiting"
            @click="resetDuplicateForm"
            >Close</b-button
          >
          <b-button
            v-if="!responseSuccess"
            variant="outline-success"
            :disabled="$v.duplicateForm.$invalid"
            @click="duplicateForms"
          >
            <b-spinner
              v-if="submiting"
              type="grow"
              label="Loading..."
            ></b-spinner
            >Create
          </b-button>
        </div>
        <div class="clearfix"></div>
      </div>
    </b-modal>
    <!-- UNPUBLISH FORM MODAL -->
    <b-modal
      id="bv-modal-unpublishFormModal"
      size="md"
      title="Unpublish Form"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      v-model="showUnpublishModal"
    >
      Are you sure you want to unpublish this form?
      <template v-slot:modal-footer>
        <b-button
          :disabled="isLoading"
          @click="onCloseUnpublishModal"
          class="mx-1"
          size="sm"
          variant="outline-danger"
          >Close</b-button
        >
        <b-button
          :disabled="isLoading"
          class="mx-1"
          @click="onSubmitUnpublishModal"
          size="sm"
          variant="outline-success"
          >Submit</b-button
        >
      </template>
    </b-modal>
    <b-modal
      id="bv-modal-updateform"
      size="lg"
      v-model="showUpdateFormModal"
      scrollable
      no-close-on-backdrop
    >
      <template v-slot:modal-header>
        <!-- Emulate built in modal header close button action -->
        <h5>Update Form {{ updateForm.updateFormTitle }}</h5>
      </template>
      <b-overlay :show="isLoading">
        <b-form-group label="Client">
          <v-select
            :searchable="true"
            :clearable="true"
            label="text"
            code="value"
            :class="
              $v.updateForm.updateFormClientId.$error ? 'validation-color' : ''
            "
            v-model="$v.updateForm.updateFormClientId.$model"
            :state="
              $v.updateForm.updateFormClientId.$dirty
                ? !$v.updateForm.updateFormClientId.$error
                : null
            "
            aria-describedby
            :options="clients"
          ></v-select>
          <p
            class="text-danger"
            style="font-size: small"
            v-if="$v.updateForm.updateFormClientId.$error"
          >
            This is a required field and one option must be selected
          </p>
        </b-form-group>

        <b-form-group label="Form Title">
          <b-form-input
            placeholder="Enter form title"
            v-model="$v.updateForm.updateFormTitle.$model"
            :state="
              $v.updateForm.updateFormTitle.$dirty
                ? !$v.updateForm.updateFormTitle.$error
                : null
            "
            aria-describedby
          ></b-form-input>
          <b-form-invalid-feedback
            >This is a required field and must be at least 5
            characters.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group label="Form Description">
          <b-form-textarea
            placeholder="Enter the form description"
            rows="3"
            v-model="$v.updateForm.updateFormDescription.$model"
            :state="
              $v.updateForm.updateFormDescription.$dirty
                ? !$v.updateForm.updateFormDescription.$error
                : null
            "
            aria-describedby
          ></b-form-textarea>
          <b-form-invalid-feedback
            >This is a required field and must be at least 10
            characters.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group label="Form Type">
          <b-form-radio-group
            disabled
            id="radio-group-1"
            v-model="$v.updateForm.updateFormselectFormType.$model"
            :state="
              $v.updateForm.updateFormselectFormType.$dirty
                ? !$v.updateForm.updateFormselectFormType.$error
                : null
            "
            :options="optionsFormType"
            name="radio-options"
          ></b-form-radio-group>
          <b-form-invalid-feedback
            >This is a required field and must be select One
            option</b-form-invalid-feedback
          >
        </b-form-group>
      </b-overlay>
      <template v-slot:modal-footer>
        <b-button
          :disabled="isLoading"
          @click="onCloseUpdateModal"
          class="mx-1"
          size="sm"
          variant="outline-danger"
          >Close</b-button
        >
        <b-button
          :disabled="isLoading"
          class="mx-1"
          @click="onSubmitUpdateFormModal"
          size="sm"
          variant="outline-success"
          >Update</b-button
        >
      </template>
    </b-modal>
    <b-modal
      id="bv-modal-moveform"
      size="lg"
      v-model="showMoveFormModal"
      scrollable
      no-close-on-backdrop
    >
      <template v-slot:modal-header>
        <!-- Emulate built in modal header close button action -->
        <h5>Move Form {{ moveForm.formTitle }}</h5>
      </template>
      <div v-if="isResponseSuccess">
        <p>
          Your Form is being moved to <b>{{ moveForm.baseUrl }}</b> ...
        </p>
      </div>
      <div v-else>
        <b-overlay :show="isLoading">
          <b-form-group
            label="Base Url"
            description="example: https://eb360.ethixdevelopment.com/"
          >
            <b-form-input
              placeholder="https://eb360.ethixdevelopment.com/"
              v-model="$v.moveForm.baseUrl.$model"
              :state="
                $v.moveForm.baseUrl.$dirty ? !$v.moveForm.baseUrl.$error : null
              "
              aria-describedby
            ></b-form-input>
            <b-form-invalid-feedback
              >This is a required field.</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group label="Enter username">
            <b-form-input
              placeholder="Enter the form description"
              v-model="$v.moveForm.username.$model"
              :state="
                $v.moveForm.username.$dirty
                  ? !$v.moveForm.username.$error
                  : null
              "
            ></b-form-input>
            <b-form-invalid-feedback
              >This is a required field and must be at least 10
              characters.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group label="Enter password">
            <b-form-input
              type="password"
              placeholder="Enter the form description"
              v-model="$v.moveForm.password.$model"
              :state="
                $v.moveForm.password.$dirty
                  ? !$v.moveForm.password.$error
                  : null
              "
            ></b-form-input>
            <b-form-invalid-feedback
              >This is a required field and must be at least 10
              characters.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-overlay>
      </div>
      <template v-slot:modal-footer>
        <b-button
          :disabled="isSubmitting"
          @click="onCloseMoveModal"
          class="mx-1"
          size="sm"
          variant="outline-danger"
          >Close</b-button
        >
        <b-button
          :disabled="isSubmitting"
          class="mx-1"
          @click="onSubmitMoveModal"
          size="sm"
          variant="outline-success"
          >Move Form <b-spinner small v-if="isSubmitting"></b-spinner
        ></b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import uid from "uid";
import moment from "moment";
import "moment/locale/es";
import { validationMixin } from "vuelidate";
import { minLength, required, url } from "vuelidate/lib/validators";
import axios from "axios";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { urls, localStorageItem } from "@/constants";
import AppLoader from "@/components/Loader/Loader";
import { Helper } from "@/helpers";

export default {
  name: "FormsContainer",
  mixins: [validationMixin],
  data() {
    return {
      perPage: 10,
      totalRows: 0,
      currentPage: 1,
      sortBy: "id",
      sortDesc: true,
      pageOptions: [5, 10, 25, 50, 75, 100],
      fields: [
        { key: "id", label: "id", sortable: true },
        { key: "type", label: "Type", sortable: true },
        { key: "title", sortable: true },
        { key: "company_name", label: "Company Name", sortable: true },
        { key: "company_id", label: "Company Id", sortable: true },
        { key: "name", label: "Creator Name", sortable: true },
        { key: "created_updated", sortable: true },
        { key: "is_active", sortable: false },
        { key: "actions", sortable: false },
        { key: "selection", label: "", sortable: false }
      ],
      filter: null,
      formTypes: [
        "External Questionaire",
        "Survey",
        "Training",
        "Exam",
        "Executive Summary",
        "PDF Uploads",
        "Internal Questionaire",
        "Signature Form",
        "Internal Company Form",
        "Certification Form"
      ],
      isLoading: false,
      submiting: false,
      isHidden: false,
      responseSuccess: false,
      placeholderVersion: "e.g. 1.0.2",
      newForm: {
        formTitle: null,
        formDescription: null,
        clientId: null,
        selectFormType: 1
      },
      optionsFormType: [
        { value: 1, text: "External Questionaire" },
        { value: 2, text: "Survey" },
        { value: 3, text: "Training" },
        { value: 4, text: "Exam" },
        { value: 5, text: "Executive Summary" },
        { value: 6, text: "PDF Uploads" },
        { value: 7, text: "Internal Questionaire" },
        { value: 8, text: "Signature Form" },
        { value: 9, text: "Internal Company Form" },
        { value: 10, text: "Certification Form" }
      ],
      translationLanguage: "english",
      showNewCreateFormModal: false,
      showDuplicateFormModal: false,
      showCollapse: true,
      clients: [],
      formTable: [],
      duplicateForm: {
        duplicateTitle: null,
        duplicateDescription: null,
        duplicateselectFormType: null,
        duplicateClientId: null
      },
      updateForm: {
        updateFormTitle: null,
        updateFormDescription: null,
        updateFormselectFormType: null,
        updateFormClientId: null
      },
      duplicateId: null,
      duplicateClientId: null,
      newFormId: null,
      unpublishFormId: null,
      showUnpublishModal: false,
      showUpdateFormModal: false,
      updateFormModalId: null,
      showMoveFormModal: false,
      moveForm: {
        formTitle: null,
        baseUrl: null,
        username: null,
        password: null
      },
      moveFormId: null,
      isResponseSuccess: false,
      isSubmitting: false
    };
  },

  validations: {
    newForm: {
      formTitle: {
        required,
        minLength: minLength(5)
      },
      formDescription: {
        required,
        minLength: minLength(10)
      },
      selectFormType: {
        required
      },
      clientId: {
        required
      }
    },
    duplicateForm: {
      duplicateTitle: {
        required,
        minLength: minLength(5)
      },
      duplicateDescription: {
        required,
        minLength: minLength(10)
      },
      duplicateselectFormType: {
        required
      },
      duplicateClientId: {
        required
      }
    },
    updateForm: {
      updateFormTitle: {
        required,
        minLength: minLength(5)
      },
      updateFormDescription: {
        required,
        minLength: minLength(10)
      },
      updateFormselectFormType: {
        required
      },
      updateFormClientId: {
        required
      }
    },
    moveForm: {
      baseUrl: {
        url,
        required
      },
      username: {
        required
      },
      password: {
        required
      }
    }
  },

  components: {
    AppLoader
  },

  computed: {
    rows() {
      return this.totalRows;
    },
    createdAt(value) {
      return moment(value).format("LLL");
    },
    ...mapGetters({
      form: "form1",
      selectedLanguage: "selectedLanguage",
      isGlobalLoader: "isLoading"
    }),
    ...mapState({
      userId: (state) => state.user.userId
    })
  },

  mounted() {
    this.onResetLocalData();
    this.getClientsList();
  },

  methods: {
    ...mapMutations(["UPDATE_FORM"]),

    age(value) {
      moment.locale("en");
      return moment.utc(value).fromNow();
    },

    onResetLocalData() {
      localStorage.removeItem("taskId");
      localStorage.removeItem("examAttemptsToDate");
      localStorage.removeItem("activeTaskStatus");
      localStorage.removeItem("activeFormSchema");
      localStorage.removeItem("examAttemptsToDate");
      localStorage.removeItem("examAttemptId");
      localStorage.removeItem("tempForm");
      localStorage.removeItem("activeFormId");
      localStorage.removeItem("userConsent");
    },

    duplicateFormModal(data) {
      this.duplicateForm.duplicateTitle = data.title;
      this.duplicateForm.duplicateDescription = data.description;
      this.duplicateForm.duplicateselectFormType = data.type;
      this.duplicateId = data.id;
      this.showDuplicateFormModal = true;
    },
    // to show unpublish frm modal
    onShowUnpublishFormModal(formId) {
      this.unpublishFormId = formId;
      this.showUnpublishModal = true;
    },
    onCloseUnpublishModal() {
      this.showUnpublishModal = false;
      this.unpublishFormId = null;
    },
    async onSubmitUnpublishModal() {
      this.isLoading = true;
      try {
        await HTTP(
          "get",
          `v1/formsunlock/${this.unpublishFormId}`,
          null,
          makeAuthorizationHeader()
        );
        this.isLoading = false;
        this.$bvToast.toast("Form Unlock Successfully", {
          variant: "success",
          autoHideDelay: 2000,
          appendToast: false
        });
        this.onCloseUnpublishModal();
        this.reloadTable();
      } catch (error) {
        this.isLoading = false;
      }
    },
    onShowMoveModal(data) {
      this.showMoveFormModal = true;
      this.moveForm.formTitle = data.title;
      this.moveFormId = data.id;
    },
    onCloseMoveModal() {
      this.showMoveFormModal = false;
      this.moveForm = {
        formTitle: null,
        baseUrl: null,
        username: null,
        password: null
      };
      this.$v.$reset();
      this.moveFormId = null;
    },
    onSubmitMoveModal() {
      this.$v.moveForm.$touch();
      if (!this.$v.moveForm.$anyError) {
        this.checkUserCredentials();
      }
    },
    async checkUserCredentials() {
      this.isSubmitting = true;
      this.isResponseSuccess = true;
      let username = this.moveForm.username;
      let password = this.moveForm.password;
      try {
        let loginData = {
          username,
          password
        };

        // This method can not be converted to the HTTP method as it does not interact with the API
        axios({
          url: `${this.moveForm.baseUrl}api/` + `authenticate/eb360`,
          method: "POST",
          data: loginData
        }).then((result) => {
          if (result && result.data.httpStatus == 200) {
            this.getFormSchema();
          } else {
            this.isSubmitting = false;
            this.isResponseSuccess = false;
            this.$bvToast.toast("Failed to Authenticate", {
              variant: "danger",
              autoHideDelay: 2000,
              appendToast: false
            });
            this.onCloseMoveModal();
          }
        });
      } catch (error) {
        this.isSubmitting = false;
        this.isResponseSuccess = false;
        this.$bvToast.toast("Failed to Authenticate", {
          variant: "danger",
          autoHideDelay: 2000,
          appendToast: false
        });
        this.onCloseMoveModal();

        return [error];
      }
    },
    async getFormSchema() {
      let result = await HTTP(
        "get",
        `v1/form/${this.moveFormId}`,
        null,
        makeAuthorizationHeader()
      );
      if (result.data.form && result.data.form.schema) {
        let form = JSON.parse(result.data.form.schema);
        form.clientId = 0;
        this.createFormOnOtherUrl(form);
      } else {
        this.isResponseSuccess = false;
        this.isSubmitting = false;
      }
    },
    async createFormOnOtherUrl(form) {
      if (form) {
        try {
          axios({
            url: `${this.moveForm.baseUrl}api/` + `v1/createform`,
            method: "POST",
            data: form,
            headers: makeAuthorizationHeader()
          }).then((result) => {
            if (result && result.data.httpStatus == 200) {
              this.$bvToast.toast(
                `Form Succesfully Moved to ${this.moveForm.baseUrl}`,
                {
                  variant: "success",
                  autoHideDelay: 2000,
                  appendToast: false
                }
              );
              this.isSubmitting = false;
              this.isResponseSuccess = false;
              this.onCloseMoveModal();
            } else {
              this.$bvToast.toast("Failed To Move Form", {
                variant: "danger",
                autoHideDelay: 2000,
                appendToast: false
              });
            }
          });
        } catch (error) {
          this.isResponseSuccess = false;
          this.isSubmitting = false;
        }
      }
    },
    onShowUpdateModal(data) {
      this.showUpdateFormModal = true;
      this.updateForm.updateFormTitle = data.title;
      this.updateForm.updateFormDescription = data.description;
      this.updateForm.updateFormselectFormType = data.type;
      this.updateForm.updateFormClientId = {
        value: `${data.company_id}`,
        text: `${data.company_name}`
      };
      this.updateFormModalId = data.id;
    },
    onCloseUpdateModal() {
      this.showUpdateFormModal = false;
      this.updateFormModalId = null;
      this.updateForm = {
        updateFormTitle: null,
        updateFormDescription: null,
        updateFormselectFormType: null,
        updateFormClientId: null
      };
      this.updateFormModalId = null;
    },
    async onSubmitUpdateFormModal() {
      // submit form
      this.$v.updateForm.$touch();
      if (!this.$v.updateForm.$anyError) {
        this.isLoading = true;
        try {
          let formData = {
            clientId: this.updateForm.updateFormClientId.value,
            formTitle: this.updateForm.updateFormTitle,
            formDescription: this.updateForm.updateFormDescription
          };
          let result = await HTTP(
            "put",
            `v1/form/update/${this.updateFormModalId}`,
            formData,
            makeAuthorizationHeader()
          );
          if (result.status == 200) {
            this.isLoading = false;
            this.$bvToast.toast("Form Updated Successfully", {
              variant: "success",
              autoHideDelay: 2000,
              appendToast: false
            });
            this.onCloseUpdateModal();
            this.myProvider();
            // reload table not working emit
            this.reloadTable();
          }
        } catch (error) {
          this.isLoading = false;
        }
      }
    },
    resetForm() {
      this.responseSuccess = false;
      this.submiting = false;
      this.isLoading = false;
      this.showNewCreateFormModal = false;
    },

    resetDuplicateForm() {
      this.responseSuccess = false;
      this.submiting = false;
      this.isLoading = false;
      this.showDuplicateFormModal = false;
    },

    async duplicateFormApiCall() {
      let formData = {
        createdById: this.userId,
        clientId: this.duplicateForm.duplicateClientId.value,
        form_id: this.duplicateId,
        formType: this.duplicateForm.duplicateselectFormType,
        formTitle: {
          english: this.duplicateForm.duplicateTitle
        },
        formDescription: {
          english: this.duplicateForm.duplicateDescription
        }
      };
      try {
        this.submiting = true;
        this.isLoading = true;
        let result = await HTTP(
          "post",
          "v1/form/duplicate",
          formData,
          makeAuthorizationHeader()
        );
        if (result && result.data.httpStatus == 200) {
          this.submiting = false;
          this.isLoading = false;
          this.responseSuccess = true;
          this.$v.$reset();
          let form = JSON.parse(result.data.form.schema);
          this.updateFormToStore(form);
        }
      } catch (error) {
        this.isLoading = false;
        this.submiting = false;
      }
    },

    duplicateForms() {
      this.$v.duplicateForm.$touch();
      if (this.$v.duplicateForm.$anyError) {
        return false;
      }
      this.duplicateFormApiCall().then(this.reloadTable);
    },

    async myProvider() {
      // Here we don't set isBusy prop, so busy state will be
      // handled by table itself
      let formData = {
        filter: this.filter,
        sort: `${this.sortBy}|${this.sortDesc == true ? "desc" : "asc"}`,
        per_page: this.perPage,
        page: this.currentPage
      };
      this.isLoading = true;
      let result = HTTP(
        "post",
        "v1/getformslist",
        formData,
        makeAuthorizationHeader()
      );

      return result
        .then((response) => {
          const items = response.data.data;
          // Here we could override the busy state, setting isBusy to false
          this.isLoading = false;
          this.totalRows = response.data.total;
          return items;
        })
        .catch(() => {
          // Here we could override the busy state, setting isBusy to false
          this.isLoading = false;
          // Returning an empty array, allows table to correctly handle
          // internal busy state in case of error
          return [];
        });
    },

    async getClientsList() {
      this.isLoading = true;
      try {
        let result = await HTTP(
          "get",
          "v1/getclientslist",
          null,
          makeAuthorizationHeader()
        );
        this.clients = result.data.clients;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },

    async lockUnlockForm(status) {
      let activeFormId = localStorage.getItem("activeFormId");
      if (!activeFormId) {
        this.$router.push({
          path: urls.ONBOARDING_TEMPLATES
        });
      }
      try {
        await HTTP(
          "put",
          `v1/form/lockstatus/${activeFormId}`,
          {
            userId: this.userId,
            lockStatus: status
          },
          makeAuthorizationHeader(),
          null,
          true
        );
      } catch ({ errorStatus }) {
        Helper.makeToast(
          this.$bvToast,
          "danger",
          `There is an unknown error with status of ${errorStatus}. Please contact support`
        );
      }
    },
    async getForm(formId, route) {
      localStorage.removeItem("activeFormId");
      this.updateFormToStore({});
      this.isLoading = true;
      let result = await HTTP(
        "get",
        `v1/form/${formId}`,
        null,
        makeAuthorizationHeader()
      );

      if (
        (result.data.form.is_locked == 1 &&
          result.data.form.locked_by_user_id == this.userId) ||
        result.data.form.is_locked == 0
      ) {
        if (result.data.form && result.data.form.schema) {
          const form = JSON.parse(result.data.form.schema);
          localStorage.setItem("activeFormId", formId);
          localStorage.setItem(
            localStorageItem.FORM_STATUS,
            result.data.form.is_active
          );
          await this.lockUnlockForm(1);
          this.updateFormToStore(form);
          localStorage.setItem("activeFormSchema", result.data.form.schema);
          this.isLoading = false;
          this.$router.push({
            path: route,
            params: {
              from: "home"
            }
          });
        }
      } else {
        this.isLoading = false;
        alert("The form is currently locked and is in use by another user");
      }
    },
    async viewUserForm(formId, route) {
      localStorage.removeItem("activeFormId");
      this.updateFormToStore({});
      this.isLoading = true;
      let result = await HTTP(
        "get",
        `v1/form/${formId}`,
        null,
        makeAuthorizationHeader()
      );
      this.isLoading = false;
      if (result.data.form && result.data.form.schema) {
        let form = result.data.form.schema;
        localStorage.setItem("activeFormId", formId);
        localStorage.setItem("activeFormSchema", form);

        this.$router.push({
          path: route,
          params: {
            from: "home"
          }
        });
      }
    },

    updateFormToStore(form) {
      this.UPDATE_FORM(form);
    },

    reloadTable() {
      this.$root.$emit("bv::refresh::table", "my-table");
    },

    async createFormApiCall() {
      this.$v.newForm.$touch();
      if (this.$v.newForm.$anyError) {
        return false;
      }
      let formData = {
        createdById: this.userId,
        copy_of_form_id: null,
        clientId: this.newForm.clientId.value,
        formId: null,
        formStyle: {
          backgroundColor: null,
          backgroundImage: null,
          backgroundSize: null,
          backgroundRepeat: null,
          backgroundPosition: null,
          height: null,
          width: null,
          opacity: 1,
          padding: {
            top: null,
            right: null,
            bottom: null,
            left: null
          },
          margin: {
            top: null,
            right: null,
            bottom: null,
            left: null
          },
          border: {
            top: null,
            right: null,
            bottom: null,
            left: null
          }
        },
        formTranslationType: "custom",
        formTitle: {
          english: this.newForm.formTitle
        },
        formDescription: {
          english: this.newForm.formDescription
        },
        formIntroText: {
          english: null
        },
        formType: this.newForm.selectFormType,
        formLayout: this.formLayout || "vertical",
        formSection: [
          {
            type: "section",
            id: uid(),
            index: 0,
            name: "",
            isRepeatable: false,
            advance: {
              hideSectionTitle: false
            },
            sectionTitle: {
              english: "Section 1 Title"
            },
            formElements: []
          }
        ],
        formStatus: 0,
        formDefaultLanguage: "english",
        formVersion: null,
        formTranslations: ["English"],
        formJSCode: null,
        formCSS: null,
        formRequirePassword: 0,
        formPassword: null,
        formConfiguration: {
          form: {},
          survey: {},
          training: {},
          exam: {
            examTimeLimit: null,
            examMaxAttempts: 5,
            examSequentialAnswer: 0
          }
        }
      };
      if (formData.formTitle.english || formData.formDescription.english)
        try {
          this.submiting = true;
          this.isLoading = true;
          this.newForm.formTitle = null;
          this.newForm.formDescription = null;
          this.newForm.clientId = null;
          let result = await HTTP(
            "post",
            "v1/createform",
            formData,
            makeAuthorizationHeader()
          );
          if (result && result.data.httpStatus == 200) {
            this.submiting = false;
            this.isLoading = false;
            this.responseSuccess = true;
            this.$v.$reset();
            let form = JSON.parse(result.data.form.schema);
            this.newFormId = result.data.formId;
            this.updateFormToStore(form);
          }
        } catch (error) {
          this.submiting = false;
          this.isLoading = false;
        }
    },
    createForm() {
      this.createFormApiCall().then(this.reloadTable);
    }
  }
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

.table-col {
  max-height: calc(100vh - 140px);
  overflow-y: scroll;
}
</style>
