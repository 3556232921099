<script>
import { themes, typographySize } from "@/constants";
import {
  isValidTheme,
  isValidTypographySize
} from "@/utils/typography/typography.utils";

export default {
  name: "BaseText",
  functional: true,
  props: {
    text: {
      type: [Array, String, Number],
      default: ""
    },
    tag: {
      type: String,
      default: "p",
      validator: (value) =>
        ["p", "li", "span", "h1", "h2", "h3", "h4"].includes(value)
    },
    size: {
      type: String,
      default: typographySize.BODY_TEXT,
      validator: (value) => Object.values(typographySize).includes(value)
    },
    theme: {
      type: String,
      default: themes.NONE,
      validator: (value) => Object.values(themes).includes(value)
    },
    margin: {
      type: String,
      default: ""
    }
  },
  render(h, context) {
    const { text, tag, size, theme, margin = "0 0 1rem" } = context.props;
    const { staticClass = "", attrs } = context.data;
    const tempSize = isValidTypographySize(size)
      ? size
      : typographySize.BODY_TEXT;
    const tempTheme = isValidTheme(theme) ? theme : themes.NONE;

    const renderMessage = (value) =>
      h(
        tag,
        {
          class: `${staticClass} base-text__${tempSize} base-text__theme--${tempTheme}`,
          style: { margin },
          attrs: {
            "data-test-id": "base-text",
            ...attrs
          }
        },
        [value]
      );

    if (text?.length > 0) {
      if (Array.isArray(text)) {
        return h(
          "div",
          { class: "base-text__wrapper" },
          text.map((textLine) => renderMessage(textLine))
        );
      } else {
        return renderMessage(text);
      }
    } else if (context.scopedSlots.default) {
      return renderMessage(context.scopedSlots.default());
    } else {
      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
.base-text {
  &__body-text {
    @include body-text;

    &--small {
      @include body-text-small;
    }

    &--italic {
      @include body-text-italic;
    }

    &--bold {
      @include body-text-bold;
    }
  }

  &__heading {
    &--one {
      @include heading-one;
    }

    &--two {
      @include heading-two;
    }

    &--three {
      @include heading-three;
    }

    &--four {
      @include heading-four;
    }
  }

  &__display-text {
    @include display-text;
  }

  &__large-text {
    @include large-text;

    &--bold {
      @include large-text-bold;
    }
  }

  @each $theme, $primary-color, $secondary-color in $themes {
    &__theme--#{$theme} {
      color: $secondary-color;
    }
  }
}
</style>
