<template>
  <a
    :class="[
      'dropdown-menu-item',
      `dropdown-menu-item--align-${getTextAlignValue()}`,
      hasIcon && 'dropdown-menu-item--has-icon'
    ]"
    data-test-id="dropdown-menu-item"
    :href="path"
    @click="handleClick"
  >
    <BaseText
      v-bind="combinedTextOptions"
      data-test-id="dropdown-menu-item__text"
    />
    <BaseIcon
      v-if="hasIcon"
      v-bind="iconOptions"
      data-test-id="dropdown-menu-item__icon"
    />
  </a>
</template>

<script>
import BaseText from "@/atoms/BaseText/BaseText";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import { textAlignMixin } from "@/mixins";

export default {
  name: "DropdownMenuItem",
  components: { BaseText, BaseIcon },
  mixins: [textAlignMixin],
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    path: {
      type: String,
      default: ""
    },
    textOptions: {
      type: Object,
      default: () => ({})
    },
    iconOptions: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    combinedTextOptions() {
      return {
        tag: "p",
        margin: "0",
        ...this.textOptions
      };
    },
    hasIcon() {
      return !!this.iconOptions?.icon;
    }
  },
  methods: {
    handleClick(event) {
      event.preventDefault();
      this.$emit("click", this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown-menu-item {
  background-color: white;
  cursor: pointer;
  padding: 7px 15px;
  text-decoration: none;
  display: block;

  &--align {
    &-left {
      text-align: left;
    }

    &-right {
      text-align: right;
    }

    &-center {
      text-align: center;
    }
  }

  &--has-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 0 7px 15px;

    &.dropdown-menu-item--right {
      flex-direction: row-reverse;
      padding: 7px 15px 7px 0;
    }
  }

  &:hover {
    background-color: $grey;
  }
}
</style>
