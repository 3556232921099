<template>
  <div
    :class="[
      'rating-custom-attribute',
      `rating-custom-attribute--text-align-${textAlign}`
    ]"
    data-test-id="rating-custom-attribute"
  >
    <b-img
      v-if="isImageSubtype"
      :src="loadImage"
      v-b-tooltip.hover.bottom
      :title="imageTooltip"
      class="rating-custom-attribute__image"
      data-test-id="rating-custom-attribute__image"
    />
    <div
      v-else
      :class="[
        'rating-custom-attribute__numeric',
        `rating-custom-attribute__numeric-theme--${getTheme()}`
      ]"
      data-test-id="rating-custom-attribute__numeric"
    >
      <BaseText
        :theme="getTheme()"
        :text="value"
        :size="typographySize.LARGE_TEXT_BOLD"
        tag="span"
        data-test-id="rating-custom-attribute__numeric_text"
      />
    </div>
  </div>
</template>

<script>
import {
  customAttributeRiskScales,
  numericScale,
  ratingTypes,
  textAlign,
  themes,
  typographySize
} from "@/constants";
import BaseText from "@/atoms/BaseText/BaseText";

export default {
  name: "RatingCustomAttribute",
  components: { BaseText },
  props: {
    value: {
      type: String,
      required: true
    },
    textAlign: {
      type: String,
      default: textAlign.CENTER
    },
    scaleType: {
      type: String,
      default: customAttributeRiskScales.ELEVEN_POINT_SCALE
    }
  },
  data() {
    return {
      typographySize
    };
  },
  computed: {
    isImageSubtype() {
      return isNaN(parseInt(this.value));
    },
    imageTooltip() {
      return this.value || "Not set";
    },
    loadImage() {
      if (this.value === ratingTypes.LOW) {
        return require(`@/assets/OK.png`);
      } else if (this.value === ratingTypes.MODERATE) {
        return require(`@/assets/WarningAlert.png`);
      } else if (this.value === ratingTypes.HIGH) {
        return require(`@/assets/HighAlert.png`);
      } else if (this.value === ratingTypes.DO_NOT_ENGAGE) {
        return require(`@/assets/doNotEngage.png`);
      } else {
        return require(`@/assets/notavailable64x64.png`);
      }
    }
  },
  methods: {
    getElevenPointScaleTheme() {
      if (parseInt(this.value) === numericScale.NUMERIC_VALUE_0) {
        return themes.GREY;
      } else if (parseInt(this.value) < numericScale.NUMERIC_VALUE_7) {
        return themes.SUCCESS;
      } else if (parseInt(this.value) < numericScale.NUMERIC_VALUE_9) {
        return themes.WARNING;
      } else {
        return themes.ERROR;
      }
    },
    getTenPointScaleTheme() {
      if (parseInt(this.value) < numericScale.NUMERIC_VALUE_5) {
        return themes.SUCCESS;
      } else if (parseInt(this.value) < numericScale.NUMERIC_VALUE_8) {
        return themes.WARNING;
      } else {
        return themes.ERROR;
      }
    },
    getTheme() {
      if (this.scaleType === customAttributeRiskScales.TEN_POINT_SCALE) {
        return this.getTenPointScaleTheme();
      }
      return this.getElevenPointScaleTheme();
    }
  }
};
</script>

<style lang="scss" scoped>
.rating-custom-attribute {
  margin-right: 10px;

  &__image {
    width: 35px;
    height: 35px;
  }

  &__numeric {
    border: 1px solid $spanish-gray;
    display: inline-flex;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    padding: 0;

    @each $theme, $primary-color in $themes {
      &-theme--#{$theme} {
        background-color: $primary-color;
      }
    }
  }

  &--text-align-center {
    text-align: center;
  }

  &--text-align-left {
    text-align: left;
  }

  &--text-align-right {
    text-align: right;
  }
}
</style>
