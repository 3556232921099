<template>
  <div
    class="entity-table-with-heading-template"
    data-test-id="entity-table-with-heading-template"
  >
    <BaseText
      :text="dueDiligenceReportPageHeading"
      tag="h4"
      margin="0 0 24px"
      :size="typographySize.LARGE_TEXT"
    />
    <BaseLoader
      v-if="dueDiligenceReportDataIsLoading"
      class="entity-table-with-heading-template__loader"
    />
    <DataTable
      v-else-if="hasTableRows"
      :ag-grid-options="agGridOptions"
      grid-height="calc(100vh - 440px)"
      ref="data-table"
      @gridReady="onGridReady"
    />
    <div
      v-else-if="dueDiligenceReportDataErrorMessage"
      class="entity-table-with-heading-template__not-available-text"
      data-test-id="entity-table-with-heading-template__not-available-text"
    >
      <BaseText
        :text="dueDiligenceReportDataErrorMessage"
        :theme="themes.ERROR_INVERSE"
      />
    </div>
    <BaseText
      v-else
      text="No reports to display"
      data-test-id="entity-table-with-heading-template__no-reports"
    />
  </div>
</template>

<script>
import BaseText from "@/atoms/BaseText/BaseText";
import {
  themes,
  UBOProfileDueDiligenceHeaders,
  ultimateBeneficialOwnersProfileDueDiligenceKeysDTO,
  typographySize
} from "@/constants";
import DataTable from "@/organisms/DataTable/DataTable";
import {
  makeUUID,
  sizeColumnsToGridWidth,
  makeDateToMatchFormat,
  getRiskIconBasedOnStatus
} from "@/utils";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import DropdownMenuWithCallToAction from "@/molecules/DropdownMenuWithCallToAction/DropdownMenuWithCallToAction";
import DropdownMenuItem from "@/molecules/DropdownMenuItem/DropdownMenuItem";
import IconWithText from "@/molecules/IconWithText/IconWithText.vue";
import { isEqual } from "lodash";

export default {
  name: "EntityTableWithHeadingTemplate",
  components: {
    BaseLoader,
    DataTable,
    BaseText
  },
  data() {
    return {
      typographySize,
      gridAPI: null,
      agGridOptions: {
        gridOptions: {
          enableBrowserTooltips: true,
          suppressRowClickSelection: true,
          rowSelection: "multiple",
          suppressAutoSizing: true
        },
        rowData: [],
        columnDefs: [],
        getRowId: (params) => params.data.id,
        rowHeight: 90
      },
      themes,
      columnInitialWidthMap: {
        [UBOProfileDueDiligenceHeaders.CREATE_DATE]: 175,
        [UBOProfileDueDiligenceHeaders.TYPE]: 120,
        [UBOProfileDueDiligenceHeaders.RISK_STATUS]: 120,
        [UBOProfileDueDiligenceHeaders.REMEDIATED_DATE]: 175,
        [UBOProfileDueDiligenceHeaders.REMEDIATED_STATUS]: 140,
        [UBOProfileDueDiligenceHeaders.ACTION]: 105
      }
    };
  },
  props: {
    dueDiligenceReportDataIsLoading: {
      type: Boolean,
      default: false
    },
    dueDiligenceReportData: {
      type: Array,
      default: () => []
    },
    dueDiligenceReportDataErrorMessage: {
      type: String,
      default: ""
    },
    dueDiligenceReportPageHeading: {
      type: String,
      default: ""
    }
  },
  computed: {
    hasTableRows() {
      return !!this.agGridOptions.rowData.length;
    }
  },
  watch: {
    dueDiligenceReportData: {
      deep: true,
      handler(newValue, oldValue) {
        if (!isEqual(newValue, oldValue)) {
          this.makeUBOTableRows(newValue);
        }
      }
    }
  },
  created() {
    this.makeUBOTableHeaders();
    if (this.dueDiligenceReportData?.length) {
      this.makeUBOTableRows(this.dueDiligenceReportData);
    }
  },
  methods: {
    onGridReady({ api, columnApi }) {
      this.gridAPI = api;
      sizeColumnsToGridWidth({
        api,
        columnApi,
        sizingElement: this.$refs?.["data-table"]?.$el,
        makeColumnLimits: this.makeColumnLimits
      });
    },
    makeColumnLimits() {
      return Object.keys(this.columnInitialWidthMap).map((key) => ({
        key,
        maxWidth: this.columnInitialWidthMap[key]
      }));
    },
    makeUBOHeaderColumn(UBOKey) {
      return {
        headerName: UBOProfileDueDiligenceHeaders[UBOKey],
        field: ultimateBeneficialOwnersProfileDueDiligenceKeysDTO[UBOKey],
        headerTooltip: UBOProfileDueDiligenceHeaders[UBOKey],
        ...this.makeValueFormatterObject(UBOKey),
        ...(this.columnInitialWidthMap[
          UBOProfileDueDiligenceHeaders[UBOKey]
        ] && {
          width:
            this.columnInitialWidthMap[UBOProfileDueDiligenceHeaders[UBOKey]]
        })
      };
    },
    makeUBOTableHeaders() {
      this.agGridOptions.columnDefs = Object.keys(
        UBOProfileDueDiligenceHeaders
      ).map((UBOKey) => {
        if (UBOKey === "ACTION") {
          return this.makeUBOActionHeaderColumn();
        } else if (UBOKey === "RISK_STATUS" || UBOKey === "REMEDIATED_STATUS") {
          return this.makeUBORiskStatusHeaderColumn(UBOKey);
        } else {
          return this.makeUBOHeaderColumn(UBOKey);
        }
      });
    },
    makeUBOActionHeaderColumn() {
      return {
        field: "ACTION",
        headerName: UBOProfileDueDiligenceHeaders.ACTION,
        cellRenderer: "BaseGridComponentWrapper",
        headerClass: "ag-cell-center",
        cellClass: "ag-cell-center",
        valueFormatter: () => null,
        width: this.columnInitialWidthMap[UBOProfileDueDiligenceHeaders.ACTION]
      };
    },
    makeActionRow(reportData) {
      return {
        component: DropdownMenuWithCallToAction,
        componentOptions: {
          menuOptions: this.makeMenuOptions(reportData)
        },
        valueFormatter: () => null
      };
    },
    makeValueFormatterObject(UBOKey) {
      if (UBOKey === "TYPE") {
        return {
          valueFormatter: (params) => params.value.toUpperCase()
        };
      }

      if (UBOKey === "CREATE_DATE" || UBOKey === "REMEDIATED_DATE") {
        return {
          valueFormatter: (params) =>
            `${makeDateToMatchFormat({
              stringifiedDate: params.value,
              dateFormat: "PPPP h:mm aaa"
            })}`
        };
      }

      return {};
    },
    makeUBORiskStatusHeaderColumn(UBOKey) {
      return {
        field: UBOKey,
        headerName: UBOProfileDueDiligenceHeaders[UBOKey],
        headerTooltip: UBOProfileDueDiligenceHeaders[UBOKey],
        width:
          this.columnInitialWidthMap[UBOProfileDueDiligenceHeaders[UBOKey]],
        cellRenderer: "BaseGridComponentWrapper",
        headerClass: "ag-cell-center",
        cellClass: "ag-cell-center",
        valueFormatter: (params) => params.value
      };
    },
    makeUBORiskStatusRow({ status, remediated }) {
      return {
        component: IconWithText,
        componentOptions: {
          ...getRiskIconBasedOnStatus({ status, remediated })
        }
      };
    },
    makeUBOTableRows(dueDiligenceReportData) {
      this.agGridOptions.rowData = dueDiligenceReportData.map((reportData) => {
        const rowId = makeUUID();
        const keys = Object.keys(reportData);
        const rowData = keys.reduce((accumulation, key) => {
          accumulation[key] =
            reportData[key] ||
            reportData[key] === false ||
            reportData[key] === 0
              ? `${reportData[key]}`
              : "-";

          return accumulation;
        }, {});

        return {
          ACTION: this.makeActionRow(reportData),
          RISK_STATUS: this.makeUBORiskStatusRow({
            status:
              reportData[
                ultimateBeneficialOwnersProfileDueDiligenceKeysDTO.RISK_STATUS
              ],
            remediated: false
          }),
          REMEDIATED_STATUS: this.makeUBORiskStatusRow({
            status:
              reportData[
                ultimateBeneficialOwnersProfileDueDiligenceKeysDTO
                  .REMEDIATED_STATUS
              ],
            remediated: true
          }),
          id: rowId,
          ...rowData
        };
      });
    },
    makeMenuOptions({
      viewReport,
      downloadReport,
      downloadRemediatedReport
    } = {}) {
      const baseOptions = [
        {
          listOptions: [
            {
              component: DropdownMenuItem,
              options: {
                id: 0,
                textOptions: {
                  text: "View Initial"
                }
              },
              listeners: {
                click: () => {
                  this.openUrlInNewTab(viewReport);
                }
              }
            },
            {
              component: DropdownMenuItem,
              options: {
                id: 1,
                textOptions: {
                  text: "Download Initial"
                }
              },
              listeners: {
                click: () => {
                  this.openUrlInNewTab(downloadReport);
                }
              }
            }
          ]
        }
      ];

      if (downloadRemediatedReport) {
        baseOptions.push({
          listOptions: [
            {
              component: DropdownMenuItem,
              options: {
                id: 2,
                textOptions: {
                  text: "Download Remediated"
                }
              },
              listeners: {
                click: () => {
                  this.openUrlInNewTab(downloadRemediatedReport);
                }
              }
            }
          ]
        });
      }

      return baseOptions;
    },
    openUrlInNewTab(path) {
      const origin = window?.location?.origin;

      window.open(`${origin}/${path}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.entity-table-with-heading-template {
  overflow: hidden;
  text-align: left;
  padding: 24px;

  &__loader {
    margin-top: 48px;
  }

  &__not-available-text {
    text-align: center;
    font-size: 20px;
  }
}
</style>
