var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'rating-custom-attribute',
    `rating-custom-attribute--text-align-${_vm.textAlign}`
  ],attrs:{"data-test-id":"rating-custom-attribute"}},[(_vm.isImageSubtype)?_c('b-img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],staticClass:"rating-custom-attribute__image",attrs:{"src":_vm.loadImage,"title":_vm.imageTooltip,"data-test-id":"rating-custom-attribute__image"}}):_c('div',{class:[
      'rating-custom-attribute__numeric',
      `rating-custom-attribute__numeric-theme--${_vm.getTheme()}`
    ],attrs:{"data-test-id":"rating-custom-attribute__numeric"}},[_c('BaseText',{attrs:{"theme":_vm.getTheme(),"text":_vm.value,"size":_vm.typographySize.LARGE_TEXT_BOLD,"tag":"span","data-test-id":"rating-custom-attribute__numeric_text"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }