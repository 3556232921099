<template>
  <div
    :class="['sidebar', sideBarCssClasses]"
    @mouseenter="onSideBarVisibility(true)"
    @mouseleave="onSideBarVisibility(false)"
  >
    <div :class="['sidebar__arrow', sideBarArrowCssClasses]">
      <font-awesome-icon
        size="lg"
        icon="chevron-circle-left"
        @click="onToggleCollapse"
      />
    </div>
    <sidebar-menu
      class="sidebar__menu bg-light"
      :menu="menu"
      :relative="true"
      theme="white-theme"
      @item-click="onItemClick"
    >
      <span slot="dropdown-icon" />
      <span slot="toggle-icon" />
    </sidebar-menu>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus.js";
import {
  localStorageItem,
  timers,
  urls,
  mutationName,
  esgTexts,
  routeNames,
  analyticsConfigurationViews
} from "@/constants";
import { hasAccessToRoute } from "@/utils";
import { mapState, mapMutations } from "vuex";

export default {
  name: "Eb2Sidebar",
  data() {
    return {
      sideBarCssClasses: "",
      sideBarCssClassAnimation: "sidebar--slide-left",
      sideBarCssClassFinishedAnimation: "sidebar--closed",
      sideBarArrowCssClasses: "",
      sideBarArrowCssClassAnimation: "sidebar__arrow--rotate",
      menu: [
        {
          title: "Analytics",
          href: urls.ANALYTICS_VIEW,
          hiddenOnCollapse: true
        },
        {
          title: "Manage Third Parties",
          href: urls.MANAGE_THIRD_PARTY,
          hiddenOnCollapse: true
        },
        {
          icon: "fa fa-leaf",
          title: esgTexts.ESG_EXPLORE,
          child: [
            {
              href: urls.ESG_THIRD_PARTIES,
              title: esgTexts.ESG_THIRD_PARTIES
            }
          ]
        },
        {
          title: "Event Log",
          href: urls.EVENT_LOG,
          hiddenOnCollapse: true
        },
        {
          title: "Platform Settings",
          hiddenOnCollapse: true,
          child: [
            {
              href: urls.USER_SETTINGS,
              title: "Users"
            },
            {
              href: urls.ROLES,
              title: "Roles"
            },
            {
              href: urls.NOTIFICATION_SETTINGS,
              title: "Notification Schemes"
            },
            {
              href: urls.BRANDING_AND_LINKS_SETTINGS,
              title: "Branding and links"
            },
            {
              href: urls.GENERAL_SETTINGS,
              title: "General"
            },
            {
              href: urls.ACTIONS,
              title: "Actions",
              hidden: !hasAccessToRoute(routeNames.ACTIONS)
            },
            {
              href: urls.AUTOMATIONS,
              title: "Automations",
              hidden: !hasAccessToRoute(routeNames.AUTOMATIONS)
            },
            {
              href: urls.CUSTOM_ATTRIBUTES,
              title: "Custom Attributes"
            },
            {
              href: urls.EMAIL_TEMPLATE,
              title: "Email Templates"
            },
            {
              href: urls.PRODUCT_VERSION,
              title: "Product Version",
              hidden: !hasAccessToRoute(routeNames.PRODUCT_VERSION)
            }
          ]
        }
      ],
      analyticsConfig: [],
      isSubmitting: false
    };
  },
  computed: {
    ...mapState({
      companyViews: (state) => state.company.companyViews,
      isSidebarOpened: (state) => state.isSidebarOpened
    }),
    isSideBarClosed() {
      return this.sideBarCssClasses.includes(
        this.sideBarCssClassFinishedAnimation
      );
    }
  },
  watch: {
    companyViews() {
      this.getAdditionalVouchers();
    }
  },
  created() {
    // React on event onUpdateCompany
    EventBus.$on("onUpdateCompany", () => {
      this.menu = [
        {
          title: "Analytics",
          icon: "fa fa-chart-bar",
          child: []
        },
        {
          icon: "fa fa-tasks",
          title: "Manage Third Parties",
          href: urls.MANAGE_THIRD_PARTY
        },
        {
          icon: "fa fa-leaf",
          title: esgTexts.ESG_EXPLORE,
          child: [
            {
              href: urls.ESG_THIRD_PARTIES,
              title: esgTexts.ESG_THIRD_PARTIES
            }
          ]
        },
        {
          icon: "fa fa-calendar-plus",
          title: "Event Log",
          href: urls.EVENT_LOG
        },
        {
          icon: "fa fa-cog",
          title: "Platform Settings",
          hiddenOnCollapse: true,
          hidden: !hasAccessToRoute(routeNames.PLATFORM_SETTINGS),
          child: [
            {
              href: urls.USER_SETTINGS,
              title: "Users"
            },
            {
              href: urls.ROLES,
              title: "Roles"
            },
            {
              href: urls.NOTIFICATION_SETTINGS,
              title: "Notification Schemes"
            },
            {
              href: urls.BRANDING_AND_LINKS_SETTINGS,
              title: "Branding and links",
              hidden: !hasAccessToRoute(routeNames.BRANDING_AND_LINKS_SETTINGS)
            },
            {
              href: urls.GENERAL_SETTINGS,
              title: "General"
            },
            {
              href: urls.ACTIONS,
              title: "Actions",
              hidden: !hasAccessToRoute(routeNames.ACTIONS)
            },
            {
              href: urls.AUTOMATIONS,
              title: "Automations",
              hidden: !hasAccessToRoute(routeNames.AUTOMATIONS)
            },
            {
              href: urls.CUSTOM_ATTRIBUTES,
              title: "Custom Attributes"
            },
            {
              href: urls.EMAIL_TEMPLATE,
              title: "Email Templates"
            },
            {
              href: urls.PRODUCT_VERSION,
              title: "Product Version",
              hidden: !hasAccessToRoute(routeNames.PRODUCT_VERSION)
            }
          ]
        }
      ];
      this.getAdditionalVouchers();
    });
  },
  mounted() {
    this.getAdditionalVouchers();
  },
  methods: {
    ...mapMutations({
      setCompanyViews: mutationName.COMPANY.SET_COMPANY_VIEWS,
      setIsSidebarOpened: mutationName.NO_NAME_SPACE.SET_IS_SIDE_BAR_OPENED
    }),
    getAdditionalVouchers() {
      this.isSubmitting = true;
      if (this.companyViews?.length) {
        this.menu = [
          {
            title: "Analytics",
            icon: "fa fa-chart-bar",
            child: []
          },
          {
            icon: "fa fa-tasks",
            title: "Manage Third Parties",
            href: urls.MANAGE_THIRD_PARTY
          },
          {
            icon: "fa fa-leaf",
            title: esgTexts.ESG_EXPLORE,
            child: [
              {
                href: urls.ESG_THIRD_PARTIES,
                title: esgTexts.ESG_THIRD_PARTIES
              }
            ]
          },
          {
            icon: "fa fa-calendar-plus",
            title: "Event Log",
            href: urls.EVENT_LOG
          },
          {
            icon: "fa fa-user",
            title: "User Views",
            child: []
          },
          {
            icon: "fa fa-cog",
            title: "Platform Settings",
            hidden: !hasAccessToRoute(routeNames.PLATFORM_SETTINGS),
            child: [
              {
                href: urls.USER_SETTINGS,
                title: "Users"
              },
              {
                href: urls.ROLES,
                title: "Roles"
              },
              {
                href: urls.NOTIFICATION_SETTINGS,
                title: "Notification Schemes"
              },
              {
                href: urls.BRANDING_AND_LINKS_SETTINGS,
                title: "Branding and links",
                hidden: !hasAccessToRoute(
                  routeNames.BRANDING_AND_LINKS_SETTINGS
                )
              },
              {
                href: urls.GENERAL_SETTINGS,
                title: "General"
              },
              {
                href: urls.ACTIONS,
                title: "Actions",
                hidden: !hasAccessToRoute(routeNames.ACTIONS)
              },
              {
                href: urls.AUTOMATIONS,
                title: "Automations",
                hidden: !hasAccessToRoute(routeNames.AUTOMATIONS)
              },
              {
                href: urls.CUSTOM_ATTRIBUTES,
                title: "Custom Attributes"
              },
              {
                href: urls.EMAIL_TEMPLATE,
                title: "Email Templates"
              },
              {
                href: urls.PRODUCT_VERSION,
                title: "Product Version",
                hidden: !hasAccessToRoute(routeNames.PRODUCT_VERSION)
              }
            ]
          }
        ];
        for (let menu of this.companyViews) {
          if (parseInt(menu.viewType) === 1) {
            this.menu[4].child.push({
              title: menu.title,
              href: `/manage/third-parties/${menu.id}`,
              icon: "fa fa-tasks"
            });
          } else if (parseInt(menu.viewType) === 2) {
            this.menu[4].child.push({
              title: menu.title,
              href: `/event-log/${menu.id}`,
              icon: "fa fa-calendar-plus"
            });
          } else {
            this.menu[4].child.push({
              title: menu.title,
              href: `/analytics-user-view/${menu.id}`,
              icon: "fa fa-chart-bar"
            });
          }
        }
      } else {
        this.menu = [
          {
            title: "Analytics",
            icon: "fa fa-chart-bar",
            child: []
          },
          {
            icon: "fa fa-tasks",
            title: "Manage Third Parties",
            href: urls.MANAGE_THIRD_PARTY
          },
          {
            icon: "fa fa-leaf",
            title: esgTexts.ESG_EXPLORE,
            child: [
              {
                href: urls.ESG_THIRD_PARTIES,
                title: esgTexts.ESG_THIRD_PARTIES
              }
            ]
          },
          {
            icon: "fa fa-calendar-plus",
            title: "Event Log",
            href: urls.EVENT_LOG
          },
          {
            icon: "fa fa-cog",
            title: "Platform Settings",
            hidden: !hasAccessToRoute(routeNames.PLATFORM_SETTINGS),
            child: [
              {
                href: urls.USER_SETTINGS,
                title: "Users"
              },
              {
                href: urls.ROLES,
                title: "Roles"
              },
              {
                href: urls.NOTIFICATION_SETTINGS,
                title: "Notification Schemes"
              },
              {
                href: urls.BRANDING_AND_LINKS_SETTINGS,
                title: "Branding and links",
                hidden: !hasAccessToRoute(
                  routeNames.BRANDING_AND_LINKS_SETTINGS
                )
              },
              {
                href: urls.GENERAL_SETTINGS,
                title: "General"
              },
              {
                href: urls.ACTIONS,
                title: "Actions",
                hidden: !hasAccessToRoute(routeNames.ACTIONS)
              },
              {
                href: urls.AUTOMATIONS,
                title: "Automations",
                hidden: !hasAccessToRoute(routeNames.AUTOMATIONS)
              },
              {
                href: urls.CUSTOM_ATTRIBUTES,
                title: "Custom Attributes"
              },
              {
                href: urls.EMAIL_TEMPLATE,
                title: "Email Templates"
              },
              {
                href: urls.PRODUCT_VERSION,
                title: "Product Version",
                hidden: !hasAccessToRoute(routeNames.PRODUCT_VERSION)
              }
            ]
          }
        ];
      }
      if (
        this.$store.state.newSidebarData &&
        this.$store.state.newSidebarData.analyticsConfig
      ) {
        this.analyticsConfig = this.$store.state.newSidebarData.analyticsConfig;
        for (let menu of this.analyticsConfig) {
          if (
            parseInt(menu.isActive) === 1 &&
            menu.title !== analyticsConfigurationViews.APRI
          ) {
            this.menu[0].child.push({
              title: menu.title,
              child: []
            });
            for (let itemMenu of menu.items) {
              this.menu[0].child.map((childItem) => {
                if (
                  childItem.title === menu.title &&
                  parseInt(itemMenu.isActive) === 1
                ) {
                  const isCompare = this.checkForComparative(itemMenu);
                  childItem.child.push({
                    title: itemMenu.title,
                    href: this.renderHref(menu, itemMenu, isCompare),
                    isComparative: isCompare
                  });
                }
              });
            }
          }
        }
      }
      this.$store.state.sidebarMenu = this.menu;
      this.isSubmitting = false;
    },
    renderHref(itemMenu, item, isCompare) {
      switch (true) {
        case itemMenu.title === analyticsConfigurationViews.APRI:
          return "/analytics-apri";
        case itemMenu.title === "Overview":
          return "/analytics-overview";
        case itemMenu.title === "Questionnaires":
          if (parseInt(isCompare) === 1) {
            return `/analytics-questionnaires-comparative/${item.id}`;
          } else {
            return `/analytics-questionnaires/${item.id}`;
          }
      }
    },
    onItemClick() {
      const singleFilteredFormData = JSON.parse(
        localStorage.getItem(localStorageItem.SINGLE_ANALYTICS)
      );
      if (singleFilteredFormData?.path !== this.$route.path) {
        localStorage.removeItem(localStorageItem.SINGLE_ANALYTICS);
      }

      const compareFilteredFormData = JSON.parse(
        localStorage.getItem(localStorageItem.COMPARE_ANALYTICS)
      );
      if (compareFilteredFormData?.path !== this.$route.path) {
        localStorage.removeItem(localStorageItem.COMPARE_ANALYTICS);
      }

      const dashboardViewFilteredFormData = JSON.parse(
        localStorage.getItem(localStorageItem.DASHBOARD_VIEW)
      );
      if (dashboardViewFilteredFormData?.path !== this.$route.path) {
        localStorage.removeItem(localStorageItem.DASHBOARD_VIEW);
      }

      this.getAdditionalVouchers();
    },
    checkForComparative(item) {
      let text = item.title;
      let check = text.includes("Comparative");
      if (check) {
        return 1;
      } else {
        return 0;
      }
    },
    renderIcon(menu) {
      switch (true) {
        case menu.title === analyticsConfigurationViews.APRI:
          return "fa fa-chart-pie";

        case menu.title === "Overview":
          return "fa fa-chart-line";

        case menu.title === "Questionnaires":
          return " fa fa-building";
      }
    },
    onSideBarVisibility(visibility) {
      if (this.isSideBarClosed) {
        this.setIsSidebarOpened(visibility);
      }
    },
    onToggleCollapse() {
      const isArrowClicked =
        this.sideBarArrowCssClasses === this.sideBarArrowCssClassAnimation;

      this.sideBarCssClasses = isArrowClicked
        ? ""
        : this.sideBarCssClassAnimation;
      this.sideBarArrowCssClasses = isArrowClicked
        ? ""
        : this.sideBarArrowCssClassAnimation;

      this.setIsSidebarOpened(isArrowClicked);

      if (!isArrowClicked) {
        setTimeout(() => {
          this.sideBarCssClasses += ` ${this.sideBarCssClassFinishedAnimation}`;
        }, timers.FAST);
      }
    },
    showAddThirdPartyModal() {
      EventBus.$emit("addModal", "");
    }
  }
};
</script>

<style lang="scss">
.third-party-add-modal-postion-tooltip {
  position: absolute !important;
  will-change: transform !important;
  top: 3.4rem !important;
  left: 0px !important;
  transform: translate3d(65px, 90px, 0px) !important;
}

.third-party-mis-page-postion-tooltip {
  position: absolute !important;
  will-change: transform !important;
  top: 8.6rem !important;
  left: 0px !important;
  transform: translate3d(65px, 90px, 0px) !important;
}
.third-party-manage-postion-tooltip {
  position: absolute !important;
  will-change: transform !important;
  top: 14rem !important;
  left: 0px !important;
  transform: translate3d(65px, 90px, 0px) !important;
}
.company-settings-postion-tooltip {
  position: absolute !important;
  will-change: transform !important;
  top: 19.3rem !important;
  left: 0px !important;
  transform: translate3d(65px, 90px, 0px) !important;
}
.sidebar {
  width: 225px;
  height: calc(100vh - 55px);
  position: fixed;
  z-index: 99;
  transition: $transition-duration;
  overflow: visible;

  & .sidebar__menu {
    max-width: none !important;
  }

  &--slide-left {
    transform: translateX(calc(-100% + 30px));

    & .vsm--scroll-wrapper {
      opacity: 0;
    }
  }

  &--closed {
    &:hover {
      transform: translateX(0);

      & .vsm--scroll-wrapper {
        opacity: 1;
      }
    }
  }

  &__arrow {
    position: absolute;
    right: -15px;
    top: 10px;
    width: 30px;
    z-index: 1000;
    transition: $transition-duration;

    &--rotate {
      transform: rotate(180deg);
    }
  }
}
.v-sidebar-menu .vsm--title {
  color: $black;
  font-weight: 450;
  padding-left: 30px;
  line-height: initial;
}
.v-sidebar-menu .vsm--link.vsm--link_exact-active .vsm--title {
  font-weight: 550;
  color: $azure !important;
  background: $bright-gray;
  line-height: 20px;
}

.v-sidebar-menu.vsm_white-theme .vsm--dropdown .vsm--list {
  background-color: $ghost-white !important;
}
.v-sidebar-menu .vsm--title {
  -ms-flex-positive: 1;
  font-size: 13px;
  padding-left: 0px;
}
.v-sidebar-menu.vsm_white-theme.vsm_expanded
  .vsm--item_open
  .vsm--link_level-1 {
  color: $white;
  background-color: $bright-gray !important;
  font-weight: bolder !important;
}
.v-sidebar-menu.vsm_white-theme .vsm--link {
  white-space: normal;
}
.v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_exact-active,
.v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_active {
  box-shadow: 3px 0px 0px 0px $white inset !important;
  background: $bright-gray;
}
.v-sidebar-menu.vsm_white-theme
  .vsm--link_level-1.vsm--link_exact-active
  .vsm--icon,
.v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_active .vsm--icon {
  color: $spanish-gray !important;
  background: $bright-gray !important;
}
.v-sidebar-menu.vsm_white-theme .vsm--link_level-1 .vsm--icon {
  background-color: $ghost-white !important;
  width: 20px !important;
}
.v-sidebar-menu.vsm_white-theme.vsm_expanded
  .vsm--item_open
  .vsm--link_level-1
  .vsm--icon {
  background-color: $bright-gray !important;
}
.v-sidebar-menu.vsm_white-theme .vsm--link {
  color: $spanish-gray !important;
}
.v-sidebar-menu .vsm--scroll-wrapper {
  height: 100%;
  margin-right: 0px !important;
  overflow-y: auto;
  overflow-x: hidden;
}
.vsm--dropdown {
  padding-left: 32px !important;
}
.v-sidebar-menu .vsm--dropdown > .vsm--list {
  padding: 0px !important;
}
.vsm--link .vsm--link_level-3 .vsm--link_active .vsm--link_exact-active {
  background-color: $bright-gray !important;
}
.v-sidebar-menu .vsm--link_level-1 {
  font-weight: bolder !important;
}
.v-sidebar-menu .vsm--link_level-2 {
  color: $white;
  background-color: $ghost-white !important;
  padding-left: 18px !important;
}
.v-sidebar-menu .vsm--link_level-2 .vsm--link_active {
  padding: 10px !important;
}
.v-sidebar-menu .vsm--link_level-2.vsm--link_exact-active {
  background: $bright-gray !important;
  margin-right: -9px;
}
.v-sidebar-menu .vsm--link_level-3 .vsm--link_active {
  color: $white;
  background-color: $bright-gray !important;
}
.vsm--link_active {
  background-color: $bright-gray !important;
  font-weight: bolder !important;
}

.v-sidebar-menu.vsm_collapsed .vsm--link {
  display: none;
}
.v-sidebar-menu.vsm_white-theme .vsm--toggle-btn {
  display: none !important;
}
.v-sidebar-menu .vsm--link.vsm--link_active.vsm--title {
  font-weight: bolder !important;
}
.v-sidebar-menu .vsm--link_level-2.vsm--link_exact-active .vsm--title {
  margin-right: -9px;
}
.v-sidebar-menu .vsm--link {
  height: auto;
}
</style>
