const variableSubType = {
  COUNTRY: "country",
  NUMERIC_11_POINT_SCALE: "11 point numeric scale",
  ETHIXBASE_RISK_SCALE: "ethiXbase Risk Scale",
  NUMERIC_10_POINT_SCALE: "10 point numeric scale"
};

const variablePrimitiveType = {
  BOOLEAN: "boolean",
  NUMERIC: "numeric",
  STRING: "string"
};

const variableType = {
  EXPRESSION: "expression",
  VARIABLE: "variable",
  PROXY: "proxy",
  DATE_TIME: "datetime",
  ...variablePrimitiveType,
  ...variableSubType
};

const variableTypeText = {
  EXPRESSION: "Calculation",
  NUMERIC: "Number",
  STRING: "String",
  BOOLEAN: "Boolean",
  PROXY: "Representation",
  DATE_TIME: "Date time"
};

const variableAssignmentType = {
  LITERAL: "literal",
  ENTITY: "entity",
  NO_VALUE: "noValue"
};

const variableScope = {
  LOCAL: "local",
  ENTITY: "entity"
};

const actionStepType = {
  END: "END",
  IF: "IF",
  SET: "SET",
  TRIGGER: "TRIGGER"
};

const ifBlockSuccessStepType = {
  SET: "IF_SET",
  END: "IF_END",
  TRIGGER: "IF_TRIGGER"
};

const calculationStepType = {
  LOGIC: "logic",
  VARIABLE: "variable",
  LITERAL: "literal"
};

const variableTypeToDisplayTextMap = {
  [variableType.NUMERIC]: variableTypeText.NUMERIC,
  [variableType.BOOLEAN]: variableTypeText.BOOLEAN,
  [variableType.STRING]: variableTypeText.STRING,
  [variableType.EXPRESSION]: variableTypeText.EXPRESSION,
  [variableType.PROXY]: variableTypeText.PROXY,
  [variableType.DATE_TIME]: variableTypeText.DATE_TIME
};

const variableDisplayTextToTypeMap = {
  [variableTypeText.NUMERIC]: variableType.NUMERIC,
  [variableTypeText.BOOLEAN]: variableType.BOOLEAN,
  [variableTypeText.STRING]: variableType.STRING,
  [variableTypeText.EXPRESSION]: variableType.EXPRESSION,
  [variableTypeText.PROXY]: variableType.PROXY,
  [variableTypeText.DATE_TIME]: variableType.DATE_TIME
};

const successStepVariableTypeMap = {
  [variableType.NUMERIC]: variableTypeText.NUMERIC,
  [variableType.BOOLEAN]: variableTypeText.BOOLEAN,
  [variableType.STRING]: variableTypeText.STRING,
  [variableType.EXPRESSION]: variableTypeText.NUMERIC,
  [variableType.PROXY]: variableTypeText.PROXY,
  [variableType.DATE_TIME]: variableTypeText.DATE_TIME
};

const inputType = {
  NUMBER: "number",
  STRING: "text"
};

const variableTypeToInputTypeMap = {
  [variableType.NUMERIC]: inputType.NUMBER,
  [variableType.STRING]: inputType.STRING
};

const expectedDataTypesText = {
  THIRD_PARTY: "Third party",
  IDD_IDD_PLUS: "IDD / IDD+",
  DDQ_FORM: "DDQ form",
  DDQ_TASK: "DDQ"
};

const expectedDataTypes = {
  THIRD_PARTY: "third-party",
  IDD_IDD_PLUS: "instant-due-diligence-report",
  DDQ_FORM: "ddq-form",
  DDQ_TASK: "ddq-task"
};

const expectedDataTypesMapText = {
  [expectedDataTypes.THIRD_PARTY]: expectedDataTypesText.THIRD_PARTY,
  [expectedDataTypes.IDD_IDD_PLUS]: expectedDataTypesText.IDD_IDD_PLUS,
  [expectedDataTypes.DDQ_FORM]: expectedDataTypesText.DDQ_FORM,
  [expectedDataTypes.DDQ_TASK]: expectedDataTypesText.DDQ_TASK
};

const automationEventType = {
  EDIT_AUTOMATION: "editAutomation",
  FETCH_AUTOMATIONS: "fetchAutomations"
};

const triggerDataType = {
  PROXY: "proxy",
  USER: "user-provided",
  IDENTIFIER: "identifier",
  LITERAL: "literal",
  VARIABLE: "variable",
  PARAMETER_MATCH: "parameter-match"
};

const actionTypes = {
  SYSTEM_ACTIONS: 1,
  CUSTOM_ACTIONS: 0
};

const actionTypesText = {
  [actionTypes.SYSTEM_ACTIONS]: "System",
  [actionTypes.CUSTOM_ACTIONS]: "User"
};

const automationsTriggerDataTypes = {
  EVENT_PARAMETER: "event-parameter",
  DDQ_FORM: "ddq-form",
  USER_PROVIDED: "user-provided"
};

const permissionTypes = {
  EDIT_CUSTOM_ATTRIBUTE_VALUE: "edit-custom-attribute-value"
};

const userViewType = {
  THIRD_PARTY: 1,
  EVENT_LOG: 2,
  USER_VIEW_ANALYTICS: 3
};

const userViewTypeText = {
  ENTITY_LIST: "EntityList",
  EVENT_LOG: "EventLog",
  DASHBOARD: "Dashboard"
};

const userViewTypeTextMapInteger = {
  [userViewType.THIRD_PARTY]: "EntityList",
  [userViewType.EVENT_LOG]: "EventLog",
  [userViewType.USER_VIEW_ANALYTICS]: "Dashboard"
};

const userViewTypeMapText = {
  [userViewTypeText.ENTITY_LIST]: 1,
  [userViewTypeText.EVENT_LOG]: 2,
  [userViewTypeText.DASHBOARD]: 3
};

const endOperations = {
  ROUND: "round"
};

const endOperationsPerType = {
  [variableType.NUMERIC]: [endOperations.ROUND]
};

const menuItemTypes = {
  DEFAULT: "default",
  ASIDE: "aside"
};

const userEmailTemplatesDestinationTypes = {
  EMAIL: "email",
  ROLE: "role",
  USER: "user"
};

export {
  variableSubType,
  variablePrimitiveType,
  variableType,
  variableTypeText,
  variableAssignmentType,
  variableScope,
  actionStepType,
  ifBlockSuccessStepType,
  calculationStepType,
  variableTypeToDisplayTextMap,
  variableDisplayTextToTypeMap,
  successStepVariableTypeMap,
  inputType,
  variableTypeToInputTypeMap,
  expectedDataTypesText,
  expectedDataTypes,
  expectedDataTypesMapText,
  automationEventType,
  triggerDataType,
  actionTypes,
  actionTypesText,
  automationsTriggerDataTypes,
  permissionTypes,
  userViewType,
  userViewTypeText,
  userViewTypeTextMapInteger,
  userViewTypeMapText,
  endOperations,
  endOperationsPerType,
  menuItemTypes,
  userEmailTemplatesDestinationTypes
};
