<template id="template-select">
  <div
    :dir="$store.state.rightAlign ? 'rtl' : 'ltr'"
    class="text-left"
    :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'"
  >
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :isHidden="isHidden"
      :label="label"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />
    <!-- preview tab -->
    <b-collapse
      v-show="JSON.parse(!fieldData.isHidden) || !isPreview"
      :class="isPreview ? 'mt-0 mb-3' : 'mt-5'"
      id="collapse-1"
      v-model="collapse"
    >
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            v-if="fieldData.isConditional == '1' && !isPreview"
            variant="outline-dark"
            disabled
            title="This field is hidden by default and will display bt is controlled element"
          >
            <font-awesome-icon icon="eye-slash" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-left">
          <b-form-group
            :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
            :id="fieldData.id"
            :description="fieldData.helperText[selectedLanguage]"
            :invalid-feedback="fieldData.validationMessage[selectedLanguage]"
          >
            <label>
              <span
                v-if="
                  fieldData.validation &&
                  JSON.parse(fieldData.validation.required)
                "
                class="required-span"
                >*</span
              >
              {{
                form1.enableSectionFieldEnumeration == 1
                  ? sectionIndex + 1 + "." + (fieldIndex + 1)
                  : ""
              }}
              {{ label ? label : fieldData.label[selectedLanguage] }}
            </label>
            <v-select
              :class="
                fieldData.validation &&
                fieldData.validation.required == 1 &&
                isFocus &&
                (!selected || selected.length == 0) &&
                $store.state.rightAlign
                  ? 'text-right validation-color'
                  : fieldData.validation &&
                    fieldData.validation.required == 1 &&
                    isFocus &&
                    (!selected || selected.length == 0)
                  ? 'text-left validation-color'
                  : $store.state.rightAlign
                  ? 'text-right'
                  : 'text-left'
              "
              :id="fieldData.id"
              :disabled="isDisabled == 1"
              :searchable="fieldData.configuration.isLiveSearchable"
              :clearable="fieldData.configuration.isClearable"
              :multiple="fieldData.configuration.isMultipleSelect"
              :options="renderOptions"
              label="text"
              value="value"
              v-model="selected"
              @input="checkCondition"
            >
              <template #no-options>
                {{ $t("questionnaires.noSearchOptionText") }}
              </template>
            </v-select>
            <label
              v-if="
                fieldData.validation &&
                fieldData.validation.required == 1 &&
                isFocus &&
                (!selected || selected.length == 0)
              "
              :class="
                $store.state.rightAlign
                  ? 'text-right text-danger'
                  : 'text-left text-danger'
              "
              >{{ fieldData.validationMessage[selectedLanguage] }}</label
            >
          </b-form-group>

          <!-- conditional input field -->
          <b-collapse v-model="showCollapseField" class="mt-2">
            <b-form-group
              :class="
                $store.state.rightAlign ? 'text-right ml-3' : 'text-left ml-3'
              "
              :description="
                fieldData.conditionalFieldHelperText[selectedLanguage]
              "
              :label="fieldData.conditionalFieldLabel[selectedLanguage]"
              :invalid-feedback="$t('createProfile.requiredValidationMessage')"
            >
              <b-form-textarea
                :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                v-model="conditionalFieldInputValue"
                @input="onInputType"
                :disabled="isDisabled == 1"
                :placeholder="
                  fieldData.conditionalFieldPlaceHolderText[selectedLanguage]
                "
                :rows="fieldData.conditionalFieldMinRows || 3"
                :max-rows="fieldData.conditionalFieldMaxRows || 6"
                :state="
                  isFocus &&
                  conditionalFieldInputValue &&
                  isMatchCondition &&
                  fieldData.validation.required == 1
                "
                @focus="isFocus = true"
              ></b-form-textarea>
            </b-form-group>
          </b-collapse>
        </b-col>
      </b-row>
      <!-- advance settings tab -->

      <b-modal
        cancel-title="Close"
        ok-title="Save"
        @ok="onSaveData"
        size="lg"
        v-model="showSettings"
        @hidden="onUpdateSettings"
        title="Select Picker Advanced Settings"
      >
        <AdvanceSelectSettings
          v-if="showSettings"
          :fieldData="fieldData"
          v-on:toggle-field="showSettings = !showSettings"
          v-on:update-form-settings="getData"
          @label-text="label = $event"
          @helper-text="helperText = $event"
          :sectionIndex="sectionIndex"
        />
      </b-modal>
    </b-collapse>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import MenuButtons from "../menuButtons";
import AdvanceSelectSettings from "@/molecules/AdvanceRadioSettingsComponent/AdvanceRadioSettingsComponent";
import { formBuilderConstants, routeKeys, routeNames } from "@/constants";
import { mapGetters, mapMutations, mapState } from "vuex";
import { EventBus } from "@/event-bus.js";
import { clone } from "lodash";

export default {
  name: "SelectGroup",
  components: {
    MenuButtons,
    AdvanceSelectSettings,
    vSelect
  },
  data() {
    return {
      isHidden: false,
      isFocus: false,
      condition: {},
      showSettings: false,
      collapse: true,
      label: undefined,
      helperText: undefined,
      isRequired: 1,
      name: "",
      renderOptions: [],
      selected: this.fieldData.selected || null,
      conditionalFieldLabel: null,
      conditionalFieldHelperText: null,
      conditionalFieldPlaceHolderText: null,
      conditionalFieldValidationMessage: null,
      conditionalFieldIsRequired: 0,
      conditionalFieldMaxRows: 1,
      conditionalFieldInputValue: null,
      conditionalFieldPostName: null,
      conditionalFieldIsVisible: 0,
      showCollapseField: false,
      isMatchCondition: false
    };
  },
  computed: {
    ...mapGetters(["form1", "selectedLanguage", "userFormSubmitArray"]),
    ...mapState(["toggleAllCoppalse"])
  },
  created() {
    EventBus.$on("onSubmitEndUserForm", (value) => {
      if (value === "progress") {
        this.$store.state.validFields = true;
        this.fieldData.isInValid = false;
      }

      if (this.fieldData.isHidden) {
        if (value !== "progress") {
          this.$store.state.validFields = true;
          this.fieldData.isInValid = false;
          this.fieldData.validation.required = 0;
        }
      } else {
        if (
          this.fieldData.validation &&
          this.fieldData.validation.required == 1
        ) {
          if (!this.selected || this.selected.length == 0) {
            this.isFocus = true;
            this.$store.state.validFields = false;
            this.fieldData.isInValid = true;
          } else if (this.selected || this.selected.length != 0) {
            if (this.isMatchCondition) {
              if (!this.conditionalFieldInputValue) {
                this.isFocus = true;
                this.$store.state.validFields = false;
                this.fieldData.isInValid = true;
              } else {
                this.$store.state.validFields = true;
                this.fieldData.isInValid = false;
              }
            }
          } else {
            this.$store.state.validFields = true;
            this.fieldData.isInValid = false;
          }
        }
      }
    });

    EventBus.$on("OnInvalidFieldCatch", (inValidData) => {
      document
        .getElementById(inValidData.elementId)
        .scrollIntoView({ behavior: "smooth" });
    });
    EventBus.$on("onClearSelectedValue", (element) => {
      if (element.id == this.fieldData.id) {
        this.selected = null;
        this.onClearComboAllDependentElements(this.fieldData);
      }
    });
  },
  watch: {
    toggleAllCoppalse(value) {
      this.collapse = value;
    },
    selectedLanguage(value) {
      this.renderOptionsWithMultiLanguage();
      if (value && this.selected) {
        this.setSelectedValueWhenLanguageChanged(value);
      }
    }
  },
  mounted() {
    this.isRequired = this.fieldData.validation.required;
    if (
      this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
      this.$route.name == "MyCompanyView"
    ) {
      this.setFieldValue();
    }
    this.renderOptionsWithMultiLanguage();
    this.conditionalFieldIsVisible = this.fieldData.conditionalFieldIsVisible;
  },
  methods: {
    ...mapMutations(["TOGGLE_LOADER"]),
    getData(data) {
      this.$emit("update-form-settings", data);
    },
    onUpdateSettings() {
      this.TOGGLE_LOADER(2);
    },
    // to clear all values for removed question
    onClearComboAllDependentElements(element) {
      let elements = this.form1.formSection[this.sectionIndex].formElements;
      let foundElement = elements.find(
        (item) => item.id == element.conditionalFields[0].targetFieldId
      );
      if (foundElement) {
        foundElement.selected = null;
        foundElement.isHidden = true;
        this.$store.state.getUserFormSubmitArray.length &&
          this.$store.state.getUserFormSubmitArray.map((a) => {
            if (a.elementId == foundElement.id) {
              a.elements = [];
            }
          });
        this.$store.state.userFormSubmitArray.length &&
          this.$store.state.userFormSubmitArray.map((a) => {
            if (a.elementId == foundElement.id) {
              a.elements = [];
            }
          });
      }
    },
    renderOptionsWithMultiLanguage() {
      this.renderOptions = this.fieldData.options.map(
        ({ value = "", text = {} }) => ({
          value: value || formBuilderConstants.NO_VALUE_SET,
          text: text[this.selectedLanguage] || formBuilderConstants.NO_TEXT_SET,
          ref: text
        })
      );
    },
    setSelectedValueWhenLanguageChanged(value) {
      if (Array.isArray(this.selected)) {
        for (const selectedOption of this.selected) {
          const changedLanguageKey = selectedOption.ref[value];
          selectedOption.value = changedLanguageKey;
          selectedOption.text = changedLanguageKey;
        }
      } else {
        const changedLanguageKey = this.selected.ref[value];
        if (changedLanguageKey) {
          this.selected.value = changedLanguageKey;
          this.selected.text = changedLanguageKey;
        }
      }
    },
    checkCondition(value) {
      this.isFocus = false;
      this.$store.state.validFields = true;
      this.fieldData.isInValid = false;
      let elements = this.form1.formSection[this.sectionIndex].formElements;
      elements.find((ff, index) => {
        if (ff.conditions && ff.conditions.length) {
          ff.conditions.map((aa) => {
            // if value is multiple select that is array
            // to show elements on conditional render
            if (value && value.length) {
              value.map((val) => {
                if (
                  aa.sourceFieldId === this.fieldData.id &&
                  aa.value === (val.value || val)
                ) {
                  this.form1.formSection[this.sectionIndex].formElements[
                    index
                  ].isHidden = false;
                  this.onShowUpdatedGetAndSubmitUserArr(ff.id);
                }
              });
            } else if (
              aa.sourceFieldId === this.fieldData.id &&
              aa.value === value
            ) {
              this.form1.formSection[this.sectionIndex].formElements[
                index
              ].isHidden = false;
            } else {
              this.form1.formSection[this.sectionIndex].formElements[
                index
              ].isHidden = true;
              this.toHideUpdatedGetAndSubmitUserArr(ff.id);
              EventBus.$emit("onClearSelectedRadioValue", ff);
            }
          });
        } else {
          this.form1.formSection[this.sectionIndex].formElements[
            index
          ].isHidden = false;
        }
      });

      // for new conditional fields..
      if (
        this.fieldData.selectedConditionalFieldValue &&
        this.fieldData.selectedConditionalFieldValue == value
      ) {
        this.showCollapseField = true;
        this.conditionalFieldIsVisible = 1;
        this.isMatchCondition = true;
      } else {
        this.showCollapseField = false;
        this.conditionalFieldIsVisible = 0;
        this.isMatchCondition = false;
      }
      this.setValue(value);
    },
    // update isHiddenElement key to to render into ddqonboarding page
    onShowUpdatedGetAndSubmitUserArr(id) {
      this.$store.state.getUserFormSubmitArray.length &&
        this.$store.state.getUserFormSubmitArray.map((a) => {
          if (a.elementId == id) {
            a.isHiddenElement = 0;
          }
        });
      this.$store.state.userFormSubmitArray.length &&
        this.$store.state.userFormSubmitArray.map((a) => {
          if (a.elementId == id) {
            a.isHiddenElement = 0;
          }
        });
    },
    toHideUpdatedGetAndSubmitUserArr(id) {
      this.$store.state.getUserFormSubmitArray.length &&
        this.$store.state.getUserFormSubmitArray.map((a) => {
          if (a.elementId == id) {
            a.elements = [];
            a.isHiddenElement = 1;
          }
        });
      this.$store.state.userFormSubmitArray.length &&
        this.$store.state.userFormSubmitArray.map((a) => {
          if (a.elementId == id) {
            a.elements = [];
            a.isHiddenElement = 1;
          }
        });
    },
    setConditionInput() {
      if (
        this.fieldData.selectedConditionalFieldValue &&
        this.fieldData.selectedConditionalFieldValue == this.selected
      ) {
        this.showCollapseField = true;
        this.conditionalFieldIsVisible = 1;
        this.isMatchCondition = true;
        this.isFocus = true;
      } else {
        this.showCollapseField = false;
        this.conditionalFieldIsVisible = 0;
        this.isMatchCondition = false;
        this.isFocus = false;
      }
    },

    setFieldValue() {
      this.$store.state.getUserFormSubmitArray.length &&
        this.$store.state.getUserFormSubmitArray.map((a) => {
          if (a.elementId === this.fieldData.id) {
            a.elements.map((el) => {
              if (el.key == "select") {
                // if array of array is coming we apply this
                if (
                  el.postValue &&
                  el.postValue[0] &&
                  el.postValue[0].length == 0
                ) {
                  return false;
                } else {
                  this.selected = el.postValue;
                }
              } else if (el.key == "progressive") {
                this.conditionalFieldInputValue = el.postValue;
              }
            });
          }
        });
      this.setConditionInput();
    },

    setValue(e) {
      const registerData = clone(this.$store.state.registerData);

      if (
        this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
        this.$route.name == "MyCompanyView"
      ) {
        this.selected = e;
        if (e && e.length > 0) {
          registerData[this.fieldData.id] = e;
        } else {
          delete registerData[this.fieldData.id];
        }
        this.$store.state.registerData = registerData;

        // main calculations
        const numkeys = Object.keys(registerData).length;
        this.$store.state.progressValue = numkeys;

        if (
          this.fieldData.configuration &&
          this.fieldData.configuration.isMultipleSelect &&
          this.selected.length
        ) {
          this.userFormSubmitArray.find((a) => {
            if (a.elementId === this.fieldData.id) {
              a.elements[0] = {
                postValue: this.selected,
                key: "select",
                postName:
                  (this.fieldData.attributes &&
                    this.fieldData.attributes.postName) ||
                  "postNameSelect"
              };
            }
          });
        } else if (this.selected) {
          let aa = [];
          aa.push(this.selected);
          this.userFormSubmitArray.find((a) => {
            if (a.elementId === this.fieldData.id) {
              a.elements[0] = {
                postValue: aa,
                key: "select",
                postName:
                  (this.fieldData.attributes &&
                    this.fieldData.attributes.postName) ||
                  "postNameSelect"
              };
            }
          });
        }
      }
    },

    onInputType(e) {
      if (
        this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
        (this.$route.name == "MyCompanyView" && this.showCollapseField)
      ) {
        this.$store.state.userFormSubmitArray.map((a) => {
          if (a.elementId === this.fieldData.id) {
            a.elements[1] = {
              postValue: e,
              key: "progressive",
              postNameProgressive:
                this.fieldData.conditionalFieldLabel || "postNameProgressive"
            };
          }
        });
      }
    },

    onSaveData() {
      EventBus.$emit("onSaveSettings", "saving settings");
    }
  },

  validations: {
    selected: {
      required
    }
  },

  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number,
    isDisabled: Number
  }
};
</script>
<style lang="scss">
.v-select.disabled .v-select-toggle[data-v-138dff1d] {
  background-color: $white !important;
}
.validation-color {
  border: 1px $spanish-red solid;
  border-radius: 6px;
}
</style>
